import Vue from 'vue'

import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VuePlyr, {
  plyr: {}
})

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 1000,
  easing: 'ease',
  offset: -64,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import VueClipboards from 'vue-clipboards'
Vue.use(VueClipboards)

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

import CountryFlag from 'vue-country-flag'
// import PhoneInput from '@/vendor/vue2-phone-input'
import Gravatar from 'vue-gravatar'

Vue.component('v-gravatar', Gravatar)
Vue.component('country-flag', CountryFlag)
// Vue.component('phone-input', PhoneInput)

import Ads from 'vue-google-adsense'

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "UA-170188052-1" },
//   appName: 'kitagaruda',
// })

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* add vue agile component */
import VueAgile from 'vue-agile'
Vue.use(VueAgile)

/* add tiptap vuetify component */
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
export default ({ app }) => {
  Vue.use(TiptapVuetifyPlugin, {
    // Below is an IMPORTANT line.
    vuetify: app.vuetify,
    iconsGroup: 'mdi'
  })
}

/* add datepicker component */
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker)

/* add vueselectimage component */
import VueSelectImage from 'vue-select-image'
// add stylesheet
require('vue-select-image/dist/vue-select-image.css')
Vue.use(VueSelectImage)