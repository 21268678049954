export const LANG = {
  en: {
    // Admin -> Login
    AMLogin: "Are you an Admin?",
    AM_LOGIN_CAPTION: "Please, Sign In with your email and password!",
    AM_LOGIN_ALERT: "Invalid Email or Password!",

    // Admin -> Menu
    AM_MENU_HOME: "Home",
    AM_MENU_WELCOME_TEXT: "Welcome Text",
    AM_MENU_TICKETS: "Manage Tickets",
    AM_MENU_TEAM_SQUAD: "Manage Team Squad",
    AM_MENU_PHOTOS: "Manage Photos",
    AM_MENU_PRODUCTS: "Manage Products",
    AM_MENU_NEWS: "Manage News",
    AM_MENU_VIDEOS: "Manage Videos",
    AM_MENU_PRIZE_CENTRE: "Manage Prize Centre",
    AM_MENU_GAME_CENTRE: "Manage Game Centre",
    AM_MENU_FAN_ZONE: "Fan Zone",
    AM_MENU_FAN_FEATURES: "Fan Features",
    AM_MENU_FAN_GALLERY: "Fan Gallery",
    AM_MENU_FAN_PROFILE: "Fan Profile",
    AM_MENU_FAN_VOTES: "Fan Votes",
    AM_MENU_FAN_DISCUSSION: "Fan Discussion",
    AM_MENU_REPORTS: "Reports",
    AM_MENU_NEWS_REPORT: "News Report",
    AM_MENU_VIDEOS_REPORT: "Videos Report",
    AM_MENU_MY_ACCOUNT: "My Account",
    AM_MENU_SIGN_OUT: "Sign Out",

    // Admin -> Home
    AM_HOME_TOTAL_SIGNUP: "Total Sign Ups",
    AM_HOME_TOTAL_SIGNUP_TODAY: "Total Sign Ups Today",
    AM_HOME_TOTAL_SIGNUP_TMONTH: "Total Sign Ups This Month",
    AM_HOME_OTHER: "Other",
    AM_HOME_OTHER_KPI: "Other KPI",
    AM_HOME_LOAD: "Load dashboard numbers",

    // Admin -> Welcome Text
    AM_WELCOME_TEXT: "Change your Welcome Text",

    // Admin -> Manage Tickets
    AM_M_TICKETS_HEADER: "Manage Tickets",
    AM_M_TICKETS_EDIT: "Edit Ticket",
    AM_M_TICKETS_PREVIEW: "Preview Ticket",
    AM_M_TICKETS_CREATE: "Create Ticket",
    AM_M_TICKETS_CANCEL: "Cancel",
    AM_M_TICKETS_DELETE: "Delete",
    AM_M_TICKETS_APPROVE: "Approve",
    AM_M_TICKETS_PUBLISH: "Publish",
    AM_M_TICKETS_PAUSE: "Pause",
    AM_M_TICKETS_RESUME: "Resume",
    AM_M_TICKETS_CREATE_FIRST: "Create Your First Ticket",
    AM_M_TICKETS_CREATE_NEW: "Create New Ticket",
    AM_M_TICKETS_UPDATE: "Update Ticket",
    AM_M_TICKETS_MAIN_BANNER: "Ticket Main Banner",
    AM_M_TICKETS_A_MATCH: "Ticket A - Match",
    AM_M_TICKETS_B_MATCH: "Ticket B - Match",
    AM_M_TICKETS_C_MATCH: "Ticket C - Match",
    AM_M_TICKETS_ADD_PHOTO: "ADD PHOTO",
    AM_M_TICKETS_LINK: "Ticket Link",
    AM_M_TICKETS_LINK_TYPE: "Type ticket link here...",

    // Admin -> TIMNAS/TEAM SQUAD
    AM_M_TIMNAS_HEADER: "Manage Team Squads",
    AM_M_TIMNAS_TITLE: "You have no team squad yet....",
    AM_M_TIMNAS_SUBHEADER: `Manage Team Squad is the place where you can manage and
                            organise
                            your team.<br><br>
                            When an ad item is approved it can be made public, other Kita Garuda users can see
                            these teams.`,
    AM_M_TIMNAS_CREATE: "Create Team Squad Now",
    AM_M_TIMNAS_EDIT: "Edit Team Squad",
    AM_M_TIMNAS_APPROVE: "Approve",

    // Admin -> TIMNAS/TEAM SQUAD -> Create/Update
    AM_M_TIMNAS_FORM_HEADER_CREATE_FIRST: "Create Your First Team",
    AM_M_TIMNAS_FORM_HEADER_CREATE: "Create New Squad",
    AM_M_TIMNAS_FORM_HEADER_UPDATE: "Update Squad",
    AM_M_TIMNAS_FORM_TEAM_NAME: "Team Name",
    AM_M_TIMNAS_FORM_CATEGORY: "Select Category",
    AM_M_TIMNAS_FORM_ADD_PLAYER: "Add Player",
    AM_M_TIMNAS_FORM_ADD_PLAYER_SPAN: "Add Player",
    AM_M_TIMNAS_FORM_ADD_OFFICIAL: "Add Official Team",
    AM_M_TIMNAS_FORM_ADD_OFFICIAL_SPAN: "Add Official",
    AM_M_TIMNAS_FORM_CREATE: "Create Team Squad",
    AM_M_TIMNAS_FORM_UPDATE: "Update Team Squad",
    AM_M_TIMNAS_FORM_CANCEL: "Cancel",
    AM_M_TIMNAS_FORM_DELETE: "Delete",

    // Admin -> TIMNAS/TEAM SQUAD -> Profile
    AM_M_TIMNAS_PROFILE_TEAM_HEDADER: "National Team Player",
    AM_M_TIMNAS_PROFILE_OFFICIAL_HEDADER: "National Team Official",
    AM_M_TIMNAS_PROFILE_FIRST_NAME: "First Name",
    AM_M_TIMNAS_PROFILE_FIRST_NAME_PL: "Type first name here...",
    AM_M_TIMNAS_PROFILE_LAST_NAME: "Last Name",
    AM_M_TIMNAS_PROFILE_LAST_NAME_PL: "Type last name here...",
    AM_M_TIMNAS_PROFILE_PLAYER_POSITION: "Player Position",
    AM_M_TIMNAS_PROFILE_OFFICIAL_POSITION: "Tim Official Position",
    AM_M_TIMNAS_PROFILE_JERSEY: "Jersey Number",
    AM_M_TIMNAS_PROFILE_TOTAL_CAPS: "Total Caps",
    AM_M_TIMNAS_PROFILE_MINUTE_PLAY: "Minutes Play",
    AM_M_TIMNAS_PROFILE_GOALS: "Goals",
    AM_M_TIMNAS_PROFILE_ASSIST: "Assist",
    AM_M_TIMNAS_PROFILE_BIRTHDAY: "Birthday",
    AM_M_TIMNAS_PROFILE_BIRTHDAY_PL: "Choose Day of Birth",
    AM_M_TIMNAS_PROFILE_BIRTHDAY_CANCEL: "Cancel",
    AM_M_TIMNAS_PROFILE_PLACE_BIRTH: "Place of Birth",
    AM_M_TIMNAS_PROFILE_HEIGHT: "Height",
    AM_M_TIMNAS_PROFILE_WEIGHT: "Weight",
    AM_M_TIMNAS_PROFILE_CURRENT_CLUB: "Current Club",
    AM_M_TIMNAS_PROFILE_JERSEY_LINK: "Jersey Link",
    AM_M_TIMNAS_PROFILE_PLAYER_PHOTO: "Player Photo",
    AM_M_TIMNAS_PROFILE_PLAYER_PHOTO_UPLOAD: "Upload Player Photo",
    AM_M_TIMNAS_PROFILE_PREVIEW: "Preview",
    AM_M_TIMNAS_PROFILE_PREV: "Prev",
    AM_M_TIMNAS_PROFILE_SAVE: "Save",
    AM_M_TIMNAS_PROFILE_NEXT: "Next",

    // Admin -> Partial
    SHOWING: "Showing last",
    ITEMS: "items",
    SEARCH_OR_FILTER: "Type to search or filter...",
    TOTAL_LIKES: "Total likes",
    CATEGORY: "Category",
    GROUP: "Group",
    DATE: "Date",
    TOTAL_IMPRESSIONS: "Total Impressions",
    TYPE_HERE: "Type Here...",
    CLOSE: "Close",

    // Admin -> Status List
    STATUS_ALL: "All",
    STATUS_DRAFT: "Draft",
    STATUS_APPROVED: "Approved",
    STATUS_PUBLISHED: "Published",
    STATUS_PAUSED: "Paused",
    STATUS_EXPIRED: "Expired",
    STATUS_CANCELLED: "Cancelled",

    // menu and module titles
    APP: "Kita Garuda",
    SLOGAN: "The place for soccer enthusiasts.",
    WELCOME: "WELCOME TO KITA GARUDA!",
    WELCOME_TEXT_UPDATED: "Welcome Text Updated",
    ONLINE: " You are online.",
    OFFLINE: " Connection lost, you are offline!",
    LANG: "Language",
    ABOUT: "About",
    ABOUT_TEXT1:
      "Mobile Web is a supporter-based platform that is exclusive to Garuda friends.",
    ABOUT_TEXT2:
      "We are committed to facilitating various activities, from discussions about the love of Indonesian football, to selling official merchandise.",
    ABOUT_TEXT3:
      "Not only as a place to interact, #KitaGaruda Mobile Web also aims to open up opportunities for close friendship between loyal national football supporters.",
    ABOUT_TEXT4:
      "We are a forum that connects spirits and helps them forge strong bonds as fellow supporters of the Indonesian National Team.",
    ABOUT_VERSION: "Version:",
    GARUDA_STORE: "Garuda Store",
    NATIONAL_TEAM_PROFILE: "National Team Profile",
    PHOTO: "Photo",
    PHOTO_GALLERY: "Photo Gallery",
    PHOTO_TEXT:
      "Portrait of the Indonesian U22 Team in the Final Match against Thailand",
    VIDEO: "Video",
    MEMBERSHIP: "Membership",

    // Get started
    LANDING: "",

    // Signup
    NAME: "Name",
    YOUR_NUMBER: "What's your number?",
    NAME_PLACEHOLDER: "Write your name here",
    EMAIL_PLACEHOLDER: "Write your email here",
    PASSWORD_PLACEHOLDER: "Write your password here",
    SINGUP_SUCCESSFUL_CHECK_EMAIL:
      "Sign-up successful! Please check your email for verification.",
    THE_CODE: "What's the code?",
    WE_TEXT: "We will text you a code to verify",
    SMS_SENT: "Sent to: ",
    ENTER_CODE: "Enter the code",
    ENTER_HERE: "Enter here",
    RE_SEND: "Resend Code",
    PROBLEMS: "Problems receiving the code?",
    INVALID_CODE: "Invalid SMS code format!",
    INV_CODE: "Invalid code!",
    CODE_MUST_BE: "The code must be 6 numbers",
    CHECK_SMS: "Please check your SMS or resend the code.",
    CHECK_NR: "Please check your number.",
    SMS_ERROR: "Error. SMS not sent!",
    ALREADY_ACCOUNT: "Already have an account?",
    LOGIN_EMAIL: "Login with your email address",
    NEW_PHONE: "New phone number?",
    CHANGE_PHONE: "Change your phone number here",
    FULL_ADDRESS: "Full Address",
    PROVINCE_PL: "Select Province",
    REGENCY_PL: "Select City/Regency",
    DISTRICT_PL: "Select District",
    VILLAGE_PL: "Select Village",
    POSTAL_CODE_PL: "Postal Code",
    FULL_ADDRESS_PL: "Write full address here",
    NIK: "Identification Number",
    NIK_PL: "Write identification number",
    PLACE_OF_BIRTH: "Place of Birth",
    PLACE_OF_BIRTH_PL: "Write place of birth",
    DATE_OF_BIRTH: "Date of Birth",
    UPLOAD_KTP: "Upload KTP",
    UPLOAD_KTP_PL: "Upload or photo KTP",
    AVATAR_PHOTO: "Click To Take Photo",

    SUBMIT_UPDATE: "Send Updated Data Now!",

    // Message Warning
    M_NAME: "Name is required",
    M_NAME_MIN: "Name must be minimal 3 characters",
    M_NAME_MAX: "Name must be less than 30 characters",
    M_EMAIL: "Email is required",
    M_EMAIL_VALID: "Email address must be valid",
    M_NIK: "NIK is required",
    M_NIK_DIGITS: "NIK must be 16 digits",
    M_POSTAL_CODE: "Kode Pos is required",
    M_POSTAL_CODE_MIN: "Kode Pos must be 5 digits",
    M_FULL_ADDRESS: "Full Address is required",
    M_FULL_ADDRESS_MIN: "Full Address 3 characters",
    M_FULL_ADDRESS_MAX: "Full Address must be less than 255 characters",
    M_PLACE_OF_BIRTH: "Place of Birth is required",
    M_PLACE_OF_BIRTH_MIN: "Place of Birth 3 characters",
    M_PLACE_OF_BIRTH_MAX: "Place of Birth must be less than 255 characters",
    M_DATE_OF_BIRTH: "Date of Birth is required",
    M_UPLOAD_KTP: "Upload KTP is required",

    // Welcom
    EMAIL_VERIFIED_SUCCESS: "Email verified successfully!",
    INVALID_VERIFICATION_CODE: "Invalid or missing verification code.",
    SUCCESS_UPDATE: "Email verified status updated in Firestore!",
    NOT_FIND_USER: "No user found with this email.",

    // Sign Up Next
    IN_ORDER:
      "In order to complete the setup of your account, we need a little more information from you...",
    ACCOUNT_CREATED: "Account created successfully.",
    CONGRATULATIONS: "WELCOME TO",
    APP_NAME: "KITA GARUDA!",
    ACC_READY:
      "Your account has been successfully registered! You can now sign in on any device with email you’ve provided.",
    ACC_TEMP_EMAIL:
      "In the event you’ve changed your email address, please use either your personal email or the Kita Garuda email provided to you in Your Account to log in again.",
    NO_EMAIL: "I don't have an email address",
    SET_PASSWORD: "Please, set your password",
    AGREE_TERMS: "I agree to the Terms and Privacy Policy",

    // Sign In
    EMAIL_ADDRESS: "Email Address",
    PASSWORD: "Password",
    SIGN_IN_HERE: "Please, Sign In here",
    PW_RECOVERY: "Password Recovery",
    ENTER_EMAIL: "Please, enter your email address",
    ENTER_NEW_EMAIL: "Please, enter your new email address",
    CHANGE_PHONE_NR: "Change Your Phone Number",
    SIGN_IN_FIRST: "Please, Sign In first",
    FORGOT_PWD: "Forgot your password?",
    RECOVER_HERE: "Recover here",
    NO_ACCOUNT: "No Account yet?",
    SIGN_UP_HERE: "Sign Up Here",
    SEND_RESET_LINK: "We will send a reset link to your email.",
    EMAIL_SENT: "Email Sent",
    CHECK_EMAIL: "Please check your inbox, an email is on the way.",
    EMAIL_INSTRUCTIONS:
      "All you have to do is open it and click the link. Of course, if you have any questions, you can contact us.",
    SIGN_IN_BUTTON: "Sign In",

    // Home
    HOME: "Home",
    SEARCH_HOME: "Search for articles and videos",
    SEARCH_VIDEO: "Search in videos...",
    SEARCH_MATCH: "Search leagues, countries...",
    SEARCH_FOR_ANYTHING: "Search for anything...",
    SEARCH_FOR: "Search for",
    NEWS_OF_THE_DAY: "NEWS OF THE DAY",
    VIDEO_OF_THE_DAY: "Video of the day",
    NEWS: "News",
    OTHER_NEWS: "Other News",
    LATEST_NEWS: "Latest News",
    MORE_VIDEOS: "Videos",
    MATCH_CENTER: "Match Results",
    MATCH_DATA: "Match Data",
    GAME_HUB: "Word Scout",
    GAME_HUB_FULL: "Word Scout",
    PRIZES: "Prizes",
    FAN_ZONE: "Fan Zone",
    FAN_ZONE_BACK: "Back to Fan Zone",
    FAN_FEATURES: "Fan Features",
    FAN_ARTICLE: "Fan Article",
    FAN_DISCUSSION: "Fan Discussion",
    LEADERBOARD: "Leaderboard",
    LOCAL_FOOTBALL: "Indonesia",
    SETTINGS: "Settings",
    COMING_SOON: "Coming Soon!",
    READ_MORE: "Read More",
    WRITTEN_BY: "Written by",
    RELATED_VIDEOS: "Related Videos",
    TRENDING_NEWS_STORIES: "TRENDING NEWS STORIES",
    SEARCH_RESULTS: "Search results",
    HOME_MUST_READ: "Stories You Must Read",
    HOME_CERITA_BOLA: "Football Stories",
    OTHER_TAGS: "Other Tags",
    SOCIAL_MADIA: "Our Social Media",
    MAIN_SPONSOR: "Main Sponsor",
    OFFICIAL_SPONSOR: "Official Sponsor",
    ADDRESS: "Address",
    SITEMAPS: "Sitemaps",
    LADDERS: "Ladders",
    TICKETS: "Tickets",
    STORE: "Store",
    OUR_HISTORY: "Our History",
    SQUADS: "Squads",
    SON_SENIOR: "SON Senior",
    SON_U23: "SON U23",
    SON_U20: "SON U20",
    SON_U16: "SON U16",
    DAUGHTER_SENIOR: "Daughter Senior",
    DAUGHTER_U19: "Daughter U19",
    BEACH_SOCCER: "Beach Soccer",
    ESPORT: "Esport",
    SUGGESTED: "Suggested",
    DONATE: "Donate",
    OUR_PARTNER: "Our Partner",
    POLLING: "Polling",
    LIVE_CHAT: "Live Chat",

    // User Menu
    MY_ACCOUNT: "My Account",
    ACC_VERIFICATION: "Account Verification",
    ACC_EMAIL: "Account Email",
    ACC_PHONE: "Account Phone",
    ACC_TYPE: "Account Type",
    ACC_STATUS: "Account Status",
    ACC_INFO: "Account Information",
    ACC_PERSONAL: "Personal Information",
    ACC_NAME: "Display Name",
    ACC_ABOUT: "About me",
    ACC_ABOUT_TEXT: "Write something about yourself...",
    ACC_FULL_NAME: "Full Legal Name",
    ACC_DOB: "Date of Birth",
    ACC_DOB_TEXT: "Add your birthday",
    ACC_GENDER: "Gender",
    ACC_MALE: "Male",
    ACC_FEMALE: "Female",
    ACC_NOT_SET: "Not Set",
    ACC_LANG: "Language",
    ACC_OB: "Kita Garuda Settings",
    ACC_TEAMS: "My Favourite Teams",
    ACC_LEAGUES: "My Leagues",
    ACC_CH_PIC: "Change Profile Picture",
    ACC_SET_PIC: "Set as Profile Picture",
    ACC_EMAIL_NOT_SET: "Your email adress has not been set",
    ACC_EMAIL_ASS:
      "We have assigned you an email address that can only be used for logging in together with your set password.",
    ACC_NOT_VERIFIED: "Your email adress has not been verified",
    ACC_VERIFIED: "Your email adress has been verified",
    ACC_VERIFY: "Please follow the link below to verify your email.",
    ACC_APP: "App",
    ACC_APP_VER: "Current Version",
    ACC_APP_TERMS: "Terms of Service",
    ACC_APP_PRIVACY: "Privacy Policy",

    // Contact Us
    CONTACT: "Contact",
    CONTACT_US: "Contact Us",
    CONTACT_US_DROP: "Drop us a line.",

    // Live Streaming
    LIVESTREAMING: "Live Streaming",
    LIVECHAT: "Live Chat",
    LIVE: "Live",

    // Match Center
    MATCH_ADDED_TO_FAV: "Match added to My Matches",
    MATCH_REMOVED_FROM_FAV: "Match removed from My Matches",

    // Prize Centre
    PRIZE_CENTRE: "Prize Centre",
    PC_WELCOME: "WELCOME TO THE KITA GARUDA PRIZE CENTRE",
    PC_WIN: "Win prizes every single week across the whole platform!",
    PC_WIN_TEXT:
      "Whether it's through exiting football games or massive discounts from our partners, reward yourself for engaging with our platform through the fantastic offers below!",
    PC_PLAY_GAME: "Go to Game Centre to play!",
    PC_SUBMIT_NOW: "Submit Now",
    PC_CLAIM: "Claim",
    PC_CLAIM_PRIZE: "Claim your Prize",
    PC_CLAIM_EMAIL:
      "In order to claim your prize, please send an email to contact@kitagaruda.id from your registered email.",
    PC_USE_NOW: "Use Now",

    // Game Centre
    GAME_CENTRE: "GAME CENTRE",
    OB_TEAM:
      "The Kita Garuda Team is working very hard to bring this exiting game to you soon...",
    ACCOUNT_REQ: "Registration is Required",
    ACCOUNT_REQ_TEXT:
      "In order to access games and play games, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",
    ACCOUNT_REQ_TEXT_ESPL:
      "In order to access the ESPL tournament, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",
    DO_LATER: "I'll do this later",
    CONF_REQ: "Confirmation Required",
    CONF_REQ_TEXT: "Please, confirm your registration for this game.",
    CONF_REQ_TEXT_ESPL: "Please, confirm your registration for ESPL.",
    CURR_PWD: "Your Current Password",
    CONF_REQ_SUCCESS: "Registration Successful",
    CONF_REQ_SUCCESS_TEXT:
      "Great! Your Registration was successfull, please check your email account and verfiy your email. Then come back here and start playing the game!",
    CONF_REQ_SUCCESS_TEXT_ESPL:
      "Great! Your Registration at ESPL was successfull, please check your email account and verfiy your email. Then come back here, login to ESPL and register for the tournament!",
    GC_VERIFY_EMAIL: "Email not Registered",
    GC_VERIFY_EMAIL_TEXT:
      "You have not registered an email address on your account, please go to My Account and verify your email first.",
    PLAY_NOW: "Play Now",
    MORE_DETAILS: "More Details",

    // Fan Zone
    FZ_TEXT: "Do you want to share your foolball knowledge with Indonesia?",
    FZ_WIN_FIFTY:
      "Get attractive prizes for the winners of the best article writers every month!",
    FZ_REQ_TEXT:
      "In order to submit acticles, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",
    FAN_SUBMISSION: "Fan Feature Submission",
    FAN_BECOME_WRITER: "Become a football writer with Kita Garuda",
    FAN_TERMS_APPLY: "Terms and Conditions apply",
    FAN_WIN_FIFTY:
      "Submit your best articles and get attractive prizes for every article we publish every month!",
    FAN_ENTER_TITLE: "Enter Article Title Here",
    FAN_ENTER_ARTICLE: "Paste or write your article here...",
    FAN_ARTICLE_CREATED: "Article Submitted!",
    FAN_ARTICLE_UPDATED: "Article Updated!",
    FAN_ARTICLE_SUBM: "Your article was successfully submitted!",
    FAN_ARTICLE_STATUS: "You can check the status of your article here.",
    FAN_ARTICLE_DRAFT_CREATED: "Article Draft Created!",
    FAN_ARTICLE_DRAFT_UPDATED: "Article Draft Updated!",
    FAN_ARTICLE_DRAFT_SAVED: "Draft Saved",
    FAN_ARTICLE_DRAFT_SAVED_TEXT: "Your draft article was successfully saved!",
    FAN_ARTICLE_CONT: "You can continue to write your article later.",
    FAN_ARTICLE_CONT_TWO:
      "To find back your article click Check Status in Fan Features or continue now.",
    FAN_ARTICLE_SEE_STATUS:
      "We will announce the winner for the best article every month - please check regularly.",
    FAN_ARTICLE_MY: "My Articles",
    SUBMIT_ARTICLE: "Submit My Article",
    SUBMIT_OTHER_ARTICLE: "Submit Another Article",
    FAN_AGREE_TERMS: "I have read and agreed to",
    FAN_AGREE_TERMS_C: "Kita Garuda Fan Feature Terms and Conditions",
    FAN_FORUM_AGREE_TERMS_C: "Fan Forum Terms and Conditions",
    FAN_MIN_MAX: "Min. 200 characters, Max. 5000 characters.",
    FAN_NO_POSTS_YET: "No posts yet",
    FAN_LAST_POST: "Last Post",
    FAN_COMMENTS: "Comments",
    FAN_FULL_NAME: "Full Name",
    FAN_FULL_NAME_PL: "Type name here...",
    FAN_EMAIL: "Email Address",
    FAN_EMAIL_PL: "Type email here...",
    FAN_ADDRESS: "Address",
    FAN_ADDRESS_PL: "Type address here...",
    FAN_PHONE: "Phone Number",
    FAN_PHONE_PL: "Type phone number here...",
    FAN_GENDER: "Gender",
    FAN_GENDER_PL: "Select a gender...",
    FAN_SLOGAN: "Your Slogan",
    FAN_SLOGAN_PL: "Type your slogan here...",
    FAN_PROFILE_PHOTO: "Profile Photo",
    FAN_PROFILE_PHOTO_UPLOAD: "UPLOAD PROFILE PHOTO",
    FAN_PROFILE_PHOTO_CAPTION: "Max. Only a Photo (400x400px size)",
    FAN_SUBMITTED_YOUR_VOTE: "You have submitted your vote",
    FAN_SUBMITTED_YOUR_ANSWER: "You have submitted your answer",

    // Gallery
    FAN_GALLERY: "Fan Gallery",
    FAN_GALLERY_SUBMITTED: "Submitted new user item for fan gallery!",
    FAN_GALLERY_DELETED: "Deleted new user item for fan gallery!",
    FAN_GALLERY_PUBLISHED: "Fan Gallery Published in bucket created!",
    FAN_GALLERY_UNPUBLISHED: "Fan Gallery Unpublished in bucket created!",
    FAN_GALLERY_MY: "My Published Submissions",
    FAN_GALLERY_SEE_STATUS:
      "Please see below the status of your published photos.",
    FAN_GALLERY_P: "Fans of the Month",
    FAN_GALLERY_TEXT:
      "Show your passion for your club and upload your photos to stand a chance to win Rp 50.000 if you’re featured as our “Fans Of The Month!”",
    FAN_GALLERY_TEXT_M:
      "We need some football funnies! Send in your most creative memes and win Rp. 20.000 every time your meme is published!",
    FAN_GALLERY_MEME: "Meme Gallery",
    FAN_GALLERY_LIKED: "Liked this photo for fan gallery!",
    FAN_REQ_TEXT:
      "In order to submit photos, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",
    FAN_MAX: "Maximal 5 photos per submission per month.",
    FAN_WRITE: "Write something about your photo...",
    FAN_ACCEPT:
      "I hereby confirm that I took these photos and I'm responsible for the content uploaded.",
    FAN_AGREE_TERMS_P: "Kita Garuda Fans of the Month Terms and Condition",
    FAN_AGREE_TERMS_M: "Kita Garuda Meme Gallery Terms and Conditions",
    FAN_SUBMITTED_BY: "Submitted by",
    FAN_MORE_INFO: "More Info",

    // Fan Creations
    FAN_CREATIONS: "Fan Creations",
    FC_SUBMITTED: "Submitted new user item for fan creations!",
    FC_DELETED: "Deleted new user item for fan creations!",
    FC_PUBLISHED: "Fan Creations Published in bucket created!",
    FC_UNPUBLISHED: "Fan Creations Unpublished in bucket created!",
    FC_MY: "My Published Submissions",
    FC_SEE_STATUS: "Please see below the status of your published photos.",
    FC_P: "Fans of the Month",
    FC_TEXT:
      "Show your passion for your club and upload your photos to stand a chance to win Rp 50.000 if you’re featured as our “Fans Of The Month!”",
    FC_TEXT_M:
      "We need some football funnies! Send in your most creative memes and win Rp. 20.000 every time your meme is published!",
    FC_MEME: "Meme Creations",
    FC_LIKED: "Liked this photo for fan creations!",
    FAN_REQ_TEXT:
      "In order to submit photos, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",
    FAN_MAX: "Maximal 5 photos per submission per month.",
    FAN_WRITE: "Write something about your photo...",
    FAN_ACCEPT:
      "I hereby confirm that I took these photos and I'm responsible for the content uploaded.",
    FAN_AGREE_TERMS_P: "Kita Garuda Fans of the Month Terms and Condition",
    FAN_AGREE_TERMS_M: "Kita Garuda Meme Creations Terms and Conditions",
    FAN_SUBMITTED_BY: "Submitted by",
    FAN_MORE_INFO: "More Info",

    // Fan Discussion
    FD_VOTE: "Fan Vote",
    FD_FORUM: "Fan Forum",
    FD_SEE_MORE: "See All Topics >",
    FD_VOTE_NOW: "Vote Now",
    FD_CAST_VOTE: "Cast your vote now",
    FD_SUBMIT_VOTE: "Submit Vote",
    FD_SIGN_UP: "Sign In / Sign UP to Vote",
    FD_FLAG_COMMENT_TXT: "Please, Confirm to Flag this Comment",
    FD_FLAG_COMMENT: "Flag Comment",
    FD_VOTE_REQ_TEXT:
      "In order to vote, please Sign In to your account. If you don't have an Kita Garuda account yet, you can Sign Up following the link bellow.",

    // Garuda Store
    PRODUCT_DETAIL: "Product Details",
    OTHER_PRODUCT: "Other Products",
    BUY_HERE: "Add to Cart",
    TERMS_CONDITIONS: "Terms and Conditions",
    BOOK_NOW: "Book Now",

    // Kumpul Bareng
    GET_TOGETHER: "Get Together",

    // Profile Fans
    FAN_PROFILE: "Fans Profile",
    SUBMIT_FAN_PROFILE: "Submit Fan Profile",
    FAN_PROFILE_SUBMITTED: "Submitted Fans Profile!",
    FAN_PROFILE_UPDATED: "Updated Fans Profile!",
    FAN_PROFILE_PUBLISHED: "Fans Profile Published in bucket created!",
    FAN_PROFILE_UNPUBLISHED: "Fans Profile Unpublished in bucket created!",

    // Team Squad
    TEAM_NATIONA: "Team National Indonesia",
    INDONESIA: "Indonesia",
    PLAYER_LIST: "Player List",
    GET_JERSEY: "Get The Jersey!",
    APPEARANCES_NUMBER: "Number of Apperances",
    APPEARANCES: "Apperances",
    PLAY_MINUTES: "Minutes of Play",
    GOALS_NUMBER: "Number of Goals",
    FEEDS_NUMBER: "Number of Feeds",
    BIRTH_DATE: "Date of Birth",
    BIRTH_PLACE: "Place of Birth",
    HEIGHT: "Height",
    WEIGHT: "Weight",
    CURRENT_CLUB: "Current Club",

    // Fan Forum
    FF_TOPIC: "Fan Forum Topic",
    FF_ALL_TOPICS: "All Topics",
    FF_POPULAR: "Most Popular",
    FF_SIGN_UP: "Sign In / Sign Up to post comments",
    FF_POST_CLUB_A: "In order to post your comments in this forum, ",
    FF_POST_CLUB_B: "needs to be set as one of your Favourite Clubs.",
    FF_POST_CLUB_B:
      'Head over to "My Account" to pick your Favourite Clubs now!',

    // Indonesia Football
    INDONESIA_FOOTBALL: "Indonesian Football",
    IND_FIXTURES: "Fixtures",
    IND_TABLE: "Table",
    IND_STANDINGS: "Standings",
    IND_CLUBS: "Clubs",
    IND_TIMNAS: "TIMNAS",
    IND_TOP_NEWS: "Top News",
    IND_CLUB_NEWS: "Club News",
    IND_LATEST_NEWS: "Latest News",
    IND_INFORMATION: "Club Information",
    IND_FAN_REEL:
      "Show your passion for your club and upload your pictures to be featured in the fan reel!",

    // Manage Tickets
    TICKET_CREATED: "Ticket Created",
    TICKET_UPDATED: "Ticket Updated",

    // Manage Team Squad
    TEAM_CREATED: "Team Created",
    TEAM_UPDATED: "Team Updated",
    TEAM_DELETED: "Team Deleted",
    PROFILE_CREATED: "Profile Created",
    PROFILE_UPDATED: "Profile Updated",

    // Manage Photos
    MAX_10_FILES: "Maximum of 10 files at once.",

    // Manage Garuda Store Products
    PRODUCT_CREATED: "Product Created",
    PRODUCT_UPDATED: "Product Updated",

    // Manage News
    NEWS_ARTICLE_CREATED: "News Article Created",
    NEWS_ARTICLE_UPDATED: "News Article Updated",
    MAX_FILES: "Maximal 5 Files per batch",
    MAX_FILESIZE: "Maximum file size of 7 MB exeeded for: ",
    MUSIC_UPLOADED: "Music Files Uploaded",
    MUSIC_UPDATED: "Music File Updated",
    RECORD_DELETED: "Record Deleted",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",

    // Manage Ads
    AD_ARTICLE_CREATED: "Advertisement Created",
    AD_ARTICLE_UPDATED: "Advertisement Updated",

    // Manage Prizes
    PRIZE_CREATED: "Prize Created",
    PRIZE_UPDATED: "Prize Updated",

    // Manage Fan Votes
    AM_VOTE_HEADLINE: "Headline",
    AM_VOTE_QUESTION: "Question",
    AM_VOTE_COVER_PHOTO: "Cover Photo",
    AM_VOTE_DESCRIPTION: "Description",
    AM_VOTING_OPTIONS: "Voting Options",
    AM_VOTING_RESULTS: "Voting Results",
    AM_VOTE_ANSWER: "Answer",
    AM_VOTE_ANSWER_COLUMN: "Answer Column",
    AM_VOTE_USER_ANSWER_TEXT: "User Answer Text...",
    AM_VOTE_ANSWER_REQUIRED: "Required fields",
    AM_VOTE_OPTION: "Option",
    AM_VOTE_DISCUSSION_ANSWER_RESULTS: "Discussion Answer Results",
    AM_VOTE_ANSWER_RESULTS: "Answer Results",
    AM_VOTE_SUMMARY: "Summary",
    AM_VOTE_INDIVIDUAL: "Individual",
    AM_VOTE_VIEW_ANSWER: "View Answer",
    AM_VOTE_RECEIVE_ANSWERS: "Receive Answers",
    AM_VOTE_EXPORT_ANSWERS: "Export Answers",
    AM_VOTE_SORT_BY: "Sort By",
    AM_VOTE_ACCOUNT_NAME: "Voters Account Name",
    AM_VOTE_LATEST: "Latest",

    // Settings
    SETTINGS: "Settings",
    PRO_CH_PL: "Please, set your password",
    SET_UPDATED: "Settings Updated",
    PWD_CHANGED: "Password has been changed",

    // Buttons
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Next",
    BUT_CONTINUE: "Continue to your account",
    BUT_CONT_DRAFT: "Continue Writing",
    BUT_SIGN_IN: "Sign In",
    BUT_SIGN_UP: "Sign Up",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Back",
    BUT_BACK_TO_SIGN_IN: "Back to Sign In",
    BUT_VERIFY: "Verify",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Change your email address",
    BUT_CHANGE: "Change",
    BUT_DONE: "Done",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Save as Draft",
    BUT_SUMBIT: "Submit",
    BUT_SUBM_PHOTO: "Submit Photos",
    BUT_VIEW_ALL: "View Detail",
    BUT_ALL_NEWS: "See All News",
    BUT_ALL_VIDEOS: "See All Videos",
    BUT_ALL_FF: "See All in Fan Forum",
    BUT_VIEW_GRID: "View Grid",
    BUT_CONFIRM: "Confirm",
    BUT_CLOSE: "Close",
    BUT_CHECK: "Check Status",
    BUT_CHECK_PHOTO: "Ckeck Publishing Status",
    BUT_LOAD_MORE: "Load More",
    SHOW_MORE: "Show More",
    DISCOVER_MORE: "Discover More",
    BUT_ESPL_ALREADY: "I already have an ESPL Account",

    // ELEVATE
    ELLEVATE_ABOUT: "About Ellevate",
    ELLEVATE_ENTER_HERE: "CONTINUE TO ELLEVATE",
  },
  id: {
    // Admin -> Login
    AMLogin: "Apakah Anda adalah Admin?",
    AM_LOGIN_CAPTION: "Silahkan, Masuk dengan email dan password Anda!",
    AM_LOGIN_ALERT: "Email atau password salah!",

    // Admin -> Menu
    AM_MENU_HOME: "Beranda",
    AM_MENU_WELCOME_TEXT: "Teks Selamat Datang",
    AM_MENU_TICKETS: "Kelola Tiket",
    AM_MENU_TEAM_SQUAD: "Kelola Timnas",
    AM_MENU_PHOTOS: "Kelola Foto",
    AM_MENU_PRODUCTS: "Kelola Produk",
    AM_MENU_NEWS: "Kelola Berita",
    AM_MENU_VIDEOS: "Kelola Video",
    AM_MENU_PRIZE_CENTRE: "Kelola Hadiah",
    AM_MENU_GAME_CENTRE: "Kelola Permainan",
    AM_MENU_FAN_ZONE: "Kolom Suporter",
    AM_MENU_FAN_FEATURES: "Data Suporter",
    AM_MENU_FAN_GALLERY: "Galeri Suporter",
    AM_MENU_FAN_PROFILE: "Profil Suporter",
    AM_MENU_FAN_VOTES: "Pilihan Suporter",
    AM_MENU_FAN_DISCUSSION: "Diskusi Suporter",
    AM_MENU_REPORTS: "Laporan",
    AM_MENU_NEWS_REPORT: "Laporan Berita",
    AM_MENU_VIDEOS_REPORT: "Laporan Video",
    AM_MENU_MY_ACCOUNT: "Akun Saya",
    AM_MENU_SIGN_OUT: "Keluar",

    // Admin -> Home
    AM_HOME_TOTAL_SIGNUP: "Total Pendaftaran",
    AM_HOME_TOTAL_SIGNUP_TODAY: "Total Pendaftaran Hari Ini",
    AM_HOME_TOTAL_SIGNUP_TMONTH: "Total Pendaftaran Bulan Ini",
    AM_HOME_OTHER: "Lainnya",
    AM_HOME_OTHER_KPI: "KPI Lainnya",
    AM_HOME_LOAD: "Muat nomor dasbor",

    // Admin -> Welcome Text
    AM_WELCOME_TEXT: "Ubah teks selamat datang",

    // Admin -> Manage Tickets
    AM_M_TICKETS_HEADER: "Kelola Tiket",
    AM_M_TICKETS_EDIT: "Edit Tiket",
    AM_M_TICKETS_PREVIEW: "Tinjau Tiket",
    AM_M_TICKETS_CREATE: "Buat Tiket",
    AM_M_TICKETS_CANCEL: "Batal",
    AM_M_TICKETS_DELETE: "Hapus",
    AM_M_TICKETS_APPROVE: "Setujui",
    AM_M_TICKETS_PUBLISH: "Terbitkan",
    AM_M_TICKETS_PAUSE: "Jeda",
    AM_M_TICKETS_RESUME: "Lanjutkan",
    AM_M_TICKETS_CREATE_FIRST: "Buat Tiket Pertama Anda",
    AM_M_TICKETS_CREATE_NEW: "Buat Tiket Baru",
    AM_M_TICKETS_UPDATE: "Perbarui Tiket",
    AM_M_TICKETS_MAIN_BANNER: "Banner Utama Tiket",
    AM_M_TICKETS_A_MATCH: "Tiket Pertandingan - A",
    AM_M_TICKETS_B_MATCH: "Tiket Pertandingan - B",
    AM_M_TICKETS_C_MATCH: "Tiket Pertandingan - C",
    AM_M_TICKETS_ADD_PHOTO: "TAMBAHKAN FOTO",
    AM_M_TICKETS_LINK: "Tautan Tiket",
    AM_M_TICKETS_LINK_TYPE: "Tulis tautan tiket disini...",

    // Admin -> TIMNAS/TEAM SQUAD
    AM_M_TIMNAS_HEADER: "Kelola Tim Nasional (Timnas)",
    AM_M_TIMNAS_TITLE: "Anda belum memiliki skuat timnas....",
    AM_M_TIMNAS_SUBHEADER: `Kelola Timnas adalah tempat di mana Anda dapat mengelola dan mengatur tim nasional Indonesia.<br><br>
                            Ketika sebuah tim telah disetujui, maka tim tersebut dapat dipublikasikan, dan pengguna Kita Garuda lainnya dapat melihat profil timnas..`,
    AM_M_TIMNAS_CREATE: "Buat Skuat Timnas Sekarang",
    AM_M_TIMNAS_EDIT: "Edit Timnas",
    AM_M_TIMNAS_APPROVE: "Setujui",

    // Admin -> TIMNAS/TEAM SQUAD -> Create/Update
    AM_M_TIMNAS_FORM_HEADER_CREATE_FIRST: "Buat Timnas Pertama",
    AM_M_TIMNAS_FORM_HEADER_CREATE: "Buat Timnas Baru",
    AM_M_TIMNAS_FORM_HEADER_UPDATE: "Perbarui Timnas",
    AM_M_TIMNAS_FORM_TEAM_NAME: "Nama Timnas",
    AM_M_TIMNAS_FORM_CATEGORY: "Pilih Kategori Timnas",
    AM_M_TIMNAS_FORM_ADD_PLAYER: "Tambahkan Pemain",
    AM_M_TIMNAS_FORM_ADD_PLAYER_SPAN: "Tambah Pemain",
    AM_M_TIMNAS_FORM_ADD_OFFICIAL: "Tambahkan Offisial Tim",
    AM_M_TIMNAS_FORM_ADD_OFFICIAL_SPAN: "Tambah Offisial",
    AM_M_TIMNAS_FORM_CREATE: "Buat Skuat",
    AM_M_TIMNAS_FORM_UPDATE: "Perbarui Skuat",
    AM_M_TIMNAS_FORM_CANCEL: "Batal",
    AM_M_TIMNAS_FORM_DELETE: "Hapus",

    // Admin -> TIMNAS/TEAM SQUAD -> Profile
    AM_M_TIMNAS_PROFILE_TEAM_HEDADER: "Pemain Tim Nasional",
    AM_M_TIMNAS_PROFILE_OFFICIAL_HEDADER: "Offisial Tim Nasional",
    AM_M_TIMNAS_PROFILE_FIRST_NAME: "Nama Depan",
    AM_M_TIMNAS_PROFILE_FIRST_NAME_PL: "Ketik nama depan...",
    AM_M_TIMNAS_PROFILE_LAST_NAME: "Nama Belakang",
    AM_M_TIMNAS_PROFILE_LAST_NAME_PL: "Ketik nama belakang...",
    AM_M_TIMNAS_PROFILE_PLAYER_POSITION: "Posisi Pemain",
    AM_M_TIMNAS_PROFILE_OFFICIAL_POSITION: "Posisi Tim Offsial",
    AM_M_TIMNAS_PROFILE_JERSEY: "Nomor Punggung",
    AM_M_TIMNAS_PROFILE_TOTAL_CAPS: "Total Pertandingan",
    AM_M_TIMNAS_PROFILE_MINUTE_PLAY: "Menit Bermain",
    AM_M_TIMNAS_PROFILE_GOALS: "Goals",
    AM_M_TIMNAS_PROFILE_ASSIST: "Assist",
    AM_M_TIMNAS_PROFILE_BIRTHDAY: "Tanggal Lahir",
    AM_M_TIMNAS_PROFILE_BIRTHDAY_PL: "Pilih Tanggal Lahir",
    AM_M_TIMNAS_PROFILE_BIRTHDAY_CANCEL: "Batal",
    AM_M_TIMNAS_PROFILE_PLACE_BIRTH: "Tempat Lahir",
    AM_M_TIMNAS_PROFILE_HEIGHT: "Tinggi Badan",
    AM_M_TIMNAS_PROFILE_WEIGHT: "Berat Badan",
    AM_M_TIMNAS_PROFILE_CURRENT_CLUB: "Klub Sekarang",
    AM_M_TIMNAS_PROFILE_JERSEY_LINK: "Jersey Link",
    AM_M_TIMNAS_PROFILE_PLAYER_PHOTO: "Foto Pemain",
    AM_M_TIMNAS_PROFILE_PLAYER_PHOTO_UPLOAD: "Unggah Foto Pemain",
    AM_M_TIMNAS_PROFILE_PREVIEW: "Pratinjau",
    AM_M_TIMNAS_PROFILE_PREV: "Kembali",
    AM_M_TIMNAS_PROFILE_SAVE: "Simpan",
    AM_M_TIMNAS_PROFILE_NEXT: "Lanjut",

    // Admin -> Partial
    SHOWING: "Menampilkan",
    ITEMS: "item terakhir",
    SEARCH_OR_FILTER: "Ketik untuk cari atau filter data...",
    CATEGORY: "Kategori",
    GROUP: "Grup",
    TOTAL_LIKES: "Total disukai",
    DATE: "Tanggal",
    TOTAL_IMPRESSIONS: "Total Tayangan",
    TYPE_HERE: "Tulis disini",
    CLOSE: "Tutup",

    // Admin -> Status List
    STATUS_ALL: "Semua",
    STATUS_DRAFT: "Draf",
    STATUS_APPROVED: "Disetujui",
    STATUS_PUBLISHED: "Diterbitkan",
    STATUS_PAUSED: "Dijeda",
    STATUS_EXPIRED: "Kadaluarsa",
    STATUS_CANCELLED: "Dibatalkan",

    // menu and module titles
    APP: "Kita Garuda",
    SLOGAN: "Tempatnya penggila sepakbola.",
    WELCOME: "SELAMAT DATANG DI KITA GARUDA!",
    WELCOME_TEXT_UPDATED: "Teks Diperbarui",
    ONLINE: " Kamu sedang online.",
    OFFLINE: " Koneksi terganggu, kamu sedang offline!",
    LANG: "Language",
    ABOUT: "Tentang",
    ABOUT_TEXT1:
      "Mobile Web adalah platform berbasis suporter yang eksklusif untuk sobat Garuda.",
    ABOUT_TEXT2:
      "Kami berkomitmen untuk memfasilitasi beragam kegiatan, mulai dari diskusi tentang kecintaan terhadap sepakbola Indonesia, hingga penjualan merchandise resmi.",
    ABOUT_TEXT3:
      "Tak hanya sebagai tempat berinteraksi, #KitaGaruda Mobile Web juga bertujuan untuk membuka peluang persahabatan yang erat antara sesama suporter setia sepakbola nasional.",
    ABOUT_TEXT4:
      "Kami adalah wadah yang menghubungkan semangat dan membantu mereka menjalin ikatan yang kuat sebagai sesama suporter Tim Nasional Indonesia.",
    ABOUT_VERSION: "Versi:",
    GARUDA_STORE: "Garuda Store",
    NATIONAL_TEAM_PROFILE: "Profil Timnas",
    PHOTO: "Foto",
    PHOTO_GALLERY: "Galeri Foto",
    PHOTO_TEXT: "Potret Tim-U22 Indonesia Pada Laga Final Kontra Thailand",
    VIDEO: "Video",
    MEMBERSHIP: "Keanggotaan",

    // Get started
    LANDING: "Penggalangan dana untuk proyek dan orang yang berarti bagi Anda",

    // Signup
    NAME: "Nama",
    YOUR_NUMBER: "Masukkan nomor telepon Anda?",
    NAME_PLACEHOLDER: "Tulis nama Anda di sini",
    EMAIL_PLACEHOLDER: "Tulis email Anda di sini",
    PASSWORD_PLACEHOLDER: "Tulis kata sandi Anda di sini",
    SINGUP_SUCCESSFUL_CHECK_EMAIL:
      "Pendaftaran berhasil! Silakan periksa email Anda untuk verifikasi.",
    THE_CODE: "Apa kodenya?",
    WE_TEXT:
      "Kami akan mengirimkan kodenya melalui SMS untuk proses verifikasi",
    SMS_SENT: "Dikirim ke: ",
    ENTER_CODE: "Masukkan kodenya",
    ENTER_HERE: "Masuk di sini",
    RE_SEND: "Kirim ulang kode",
    PROBLEMS: "Ada masalah saat menerima kode?",
    INVALID_CODE: "Format kode SMS salah!",
    INV_CODE: "Kode tidak valid!",
    CODE_MUST_BE: "Kode harus terdiri dari 6 angka",
    CHECK_SMS: "Silahkan cek SMS Anda atau kirim ulang kodenya.",
    CHECK_NR: "Silahkan cek nomor ponsel Anda.",
    SMS_ERROR: "Terjadi kesalahan. SMS tidak terkirim!",
    ALREADY_ACCOUNT: "Sudah memiliki akun?",
    LOGIN_EMAIL: "Silahkan login dengan email Anda",
    NEW_PHONE: "Memiliki nomor ponsel yang baru?",
    CHANGE_PHONE: "Ubah nomor ponsel Anda di sini",
    FULL_ADDRESS: "Alamat Lengkap",
    PROVINCE_PL: "Pilih Provinsi",
    REGENCY_PL: "Pilih Kota/Kabupaten",
    DISTRICT_PL: "Pilih Kecamatan",
    VILLAGE_PL: "Pilih Desa/Kelurahan",
    POSTAL_CODE_PL: "Kode Pos",
    FULL_ADDRESS_PL: "Masukan Alamat Lengkap",
    NIK: "Nomor Induk Kependudukan (NIK)",
    NIK_PL: "Masukan NIK",
    PLACE_OF_BIRTH: "Tempat Lahir",
    PLACE_OF_BIRTH_PL: "Masukan Tempat Lahir",
    DATE_OF_BIRTH: "Tanggal Lahir",
    UPLOAD_KTP: "Unggah KTP",
    UPLOAD_KTP_PL: "Unggah atau Foto KTP",
    AVATAR_PHOTO: "Klik Untuk Ambil Foto",
    
    SUBMIT_UPDATE: "Kirim Data Perubahan!",

    // Message Warning
    M_NAME: "Nama wajib diisi",
    M_NAME_MIN: "Nama harus minimal 3 karakter",
    M_NAME_MAX: "Nama harus kurang dari 30 karakter",
    M_EMAIL: "Email wajib diisi",
    M_EMAIL_VALID: "Alamat email harus valid",
    M_NIK: "NIK wajib diisi",
    M_NIK_DIGITS: "NIK wajib 16 digit angka",
    M_POSTAL_CODE: "Kode pos wajib diisi",
    M_POSTAL_CODE_MIN: "Kode pos harus 5 digit angka",
    M_FULL_ADDRESS: "Alamat lengkap wajib diisi",
    M_FULL_ADDRESS_MIN: "Alamat lengkap harus minimal 3 karakter",
    M_FULL_ADDRESS_MAX: "Alamat lengkap harus kurang dari 255 karakter",
    M_PLACE_OF_BIRTH: "Tempat lahir wajib diisi",
    M_PLACE_OF_BIRTH_MIN: "Tempat lahir harus minimal 3 karakter",
    M_PLACE_OF_BIRTH_MAX: "Tempat lahir harus kurang dari 255 karakter",
    M_DATE_OF_BIRTH: "Tanggal lahir wajib diisi",
    M_UPLOAD_KTP: "Wajib upload KTP",

    // Welcom
    EMAIL_VERIFIED_SUCCESS: "Email berhasil diverifikasi!",
    INVALID_VERIFICATION_CODE: "Kode verifikasi tidak valid atau hilang.",
    SUCCESS_UPDATE: "Status terverifikasi email diperbarui di Firestore!",
    NOT_FIND_USER: "Tidak ada pengguna yang ditemukan dengan email ini.",

    // Sign Up Next
    IN_ORDER:
      "Untuk melengkapi proses pembuatan akun, kami membutuhkan sedikit informasi tentang Anda...",
    ACCOUNT_CREATED: "Akun sudah berhasil dibuat",
    CONGRATULATIONS: "SELAMAT DATANG DI",
    APP_NAME: "KITA GARUDA!",
    ACC_READY:
      "Akun Anda telah berhasil didaftarkan! Anda sekarang dapat masuk di perangkat apa pun dengan email yang Anda berikan.",
    ACC_TEMP_EMAIL:
      "Jika Anda telah mengubah alamat email Anda, silakan gunakan email pribadi Anda atau email Kita Garuda yang diberikan kepada Anda di Akun Anda untuk login kembali.",
    NO_EMAIL: "Saya tidak punya alamat email",
    SET_PASSWORD: "Silahkan buat password Anda",
    AGREE_TERMS: "Saya mengetujui persyaratan dan kebijakan privasi",

    // Sign In
    EMAIL_ADDRESS: "Alamat Email",
    PASSWORD: "Kata Sandi",
    SIGN_IN_HERE: "Silahkan, login di sini",
    PW_RECOVERY: "Pemulihan Kata Sandi",
    ENTER_EMAIL: "Silahkan ketik alamat email Anda",
    ENTER_NEW_EMAIL: "Silahkan ketik alamat email baru.",
    CHANGE_PHONE_NR: "Ubah nomor ponsel Anda",
    SIGN_IN_FIRST: "Silahkan login terlebih dahulu",
    FORGOT_PWD: "Lupa Kata Sandi?",
    RECOVER_HERE: "Pulihkan di sini",
    NO_ACCOUNT: "Belum punya akun?",
    SIGN_UP_HERE: "Daftar di sini",
    SEND_RESET_LINK: "Kami akan mengirimkan link reset ke email Anda.",
    EMAIL_SENT: "Email terkirim",
    CHECK_EMAIL: "Silahkan cek inbox Anda, kami baru saja mengirimkan email.",
    EMAIL_INSTRUCTIONS:
      "Silahkan cek inbox Anda, kami baru saja mengirimkan email. Coba buka email dari kami dan klik link yang tersedia. Tentu saja, jika Anda memiliki pertanyaan, silahkan hubungi kami.",
    SIGN_IN_BUTTON: "Masuk",

    // Home
    HOME: "Beranda",
    SEARCH_HOME: "Cari artikel & video disni",
    SEARCH_MATCH: "Cari liga dan negara...",
    SEARCH_VIDEO: "Cari di video...",
    SEARCH_FOR_ANYTHING: "Cari apa saja...",
    SEARCH_FOR: "Cari",
    NEWS_OF_THE_DAY: "BERITA HARI",
    VIDEO_OF_THE_DAY: "Video hari ini",
    NEWS: "Berita",
    OTHER_NEWS: "Berita Lainnya",
    LATEST_NEWS: "Berita Terkini",
    MORE_VIDEOS: "Video",
    MATCH_CENTER: "Hasil Pertandingan",
    MATCH_DATA: "Data Pertandingan",
    GAME_HUB: "Pramuka Kata",
    GAME_HUB_FULL: "Pramuka Kata",
    PRIZES: "Hadiah",
    FAN_ZONE: "Kolom Fans",
    FAN_ZONE_BACK: "Kembali ke Kolom Fans",
    FAN_FEATURES: "Fitur Fans",
    FAN_ARTICLE: "Artikel Fans",
    FAN_DISCUSSION: "Diskusi Fans",
    LOCAL_FOOTBALL: "Indonesia",
    LEADERBOARD: "Peringkat",
    SETTINGS: "Pengaturan",
    COMING_SOON: "Segera Hadir!",
    READ_MORE: "Baca Selengkapnya",
    WRITTEN_BY: "Ditulis oleh",
    RELATED_VIDEOS: "Video Terkait",
    TRENDING_NEWS_STORIES: "BERITA UTAMA",
    SEARCH_RESULTS: "Hasil Pencarian",
    HOME_MUST_READ: "Cerita Yang Harus anda Baca",
    HOME_CERITA_BOLA: "Cerita Bola",
    OTHER_TAGS: "Tag lainnya",
    SOCIAL_MADIA: "Media Sosial Kami",
    MAIN_SPONSOR: "Sponsor Utama",
    OFFICIAL_SPONSOR: "Sponsor Resmi",
    ADDRESS: "Alamat",
    SITEMAPS: "Peta Situs",
    LADDERS: "Ladders",
    TICKETS: "Tiket",
    STORE: "Kedai",
    OUR_HISTORY: "Sejarah kita",
    SQUADS: "Squads",
    SON_SENIOR: "Putra Senior",
    SON_U23: "Putra U23",
    SON_U20: "Putra U20",
    SON_U16: "Putra U16",
    DAUGHTER_SENIOR: "Putri Senior",
    DAUGHTER_U19: "Putri U19",
    BEACH_SOCCER: "Sepak Bola Pantai",
    ESPORT: "Esport",
    SUGGESTED: "Disarankan",
    DONATE: "Menyumbangkan",
    OUR_PARTNER: "Mitra Kami",
    POLLING: "Jajak Pendapat",
    LIVE_CHAT: "Obrolan Langsung",

    // User Menu
    MY_ACCOUNT: "Akun Saya",
    ACC_VERIFICATION: "Verifikasi akun",
    ACC_EMAIL: "Email akun",
    ACC_PHONE: "Nomor ponsel ",
    ACC_TYPE: "Tipe akun",
    ACC_STATUS: "Status akun",
    ACC_INFO: "Informasi akun",
    ACC_PERSONAL: "Informasi pribadi",
    ACC_NAME: "Nama tampilan",
    ACC_ABOUT: "Tentang saya",
    ACC_ABOUT_TEXT: "Tulis sesuatu tentang diri Anda...",
    ACC_FULL_NAME: "Nama lengkap",
    ACC_DOB: "Tanggal lahir",
    ACC_DOB_TEXT: "Masukkan tanggal lahir Anda",
    ACC_GENDER: "Jenis kelamin",
    ACC_MALE: "Pria",
    ACC_FEMALE: "Wanita",
    ACC_NOT_SET: "Belum diatur",
    ACC_LANG: "Bahasa",
    ACC_OB: "Pengaturan Kita Garuda",
    ACC_TEAMS: "Tim Favorit Saya",
    ACC_LEAGUES: "Liga pilihan saya",
    ACC_CH_PIC: "Ganti Foto Profil",
    ACC_SET_PIC: "Simpan Gambar Profil",
    ACC_EMAIL_NOT_SET: "Email Anda belum di set",
    ACC_EMAIL_ASS:
      "Kami telah memberikan Anda alamat email yang hanya dapat digunakan untuk login bersama dengan kata sandi yang Anda buat.",
    ACC_NOT_VERIFIED: "Email Anda belum diverifikasi",
    ACC_VERIFIED: "Email Anda sudah diverifikasi",
    ACC_VERIFY: "Silahkan klik link dibawah ini untuk verifikasi email Anda.",
    ACC_APP: "App",
    ACC_APP_VER: "Versi",
    ACC_APP_TERMS: "Ketentuan Layanan",
    ACC_APP_PRIVACY: "Kebijakan Privasi",

    // Contact Us
    CONTACT: "Hubungi",
    CONTACT_US: "Hubungi Kami",
    CONTACT_US_DROP: "Kirimi kami pesan.",

    // Live Streaming
    LIVESTREAMING: "Siaran Langsung",
    LIVECHAT: "Obrolan Langsung",
    LIVE: "Langsung",

    // Match Center
    MATCH_ADDED_TO_FAV: "Ditambahkan ke Pertandingan Saya",
    MATCH_REMOVED_FROM_FAV: "Ditambahkan ke Pertandingan Saya",

    // Prize Centre
    PRIZE_CENTRE: "Hadiah",
    PC_WELCOME: "SELAMAT DATANG DI PUSATNYA HADIAH KITA GARUDA",
    PC_WIN:
      "Menangkan hadiah fantastis setiap minggunya hanya dengan mengakses aplikasi ini!",
    PC_WIN_TEXT:
      "Anda bisa mendapatkan berbagai penawaran eksklusif baik itu dari berbagai games seru sepak bola maupun diskon besar-besaran dari mitra kami!",
    PC_PLAY_GAME: "Pergi ke Games untuk bermain!",
    PC_SUBMIT_NOW: "Dafter Sekarang",
    PC_CLAIM: "Klaim",
    PC_CLAIM_PRIZE: "Klaim Hadiah",
    PC_CLAIM_EMAIL:
      "Untuk klaim hadiah Anda, silahkan kirim email ke contact@kitagaruda.id dengan subjek: Klaim Hadiah dari email Anda yang sudah terdaftar.",
    PC_USE_NOW: "Gunakan Sekarang",

    // Game Centre
    GAME_CENTRE: "GAMES",
    OB_TEAM:
      "Tim Kita Garuda masih bekerja keras untuk segera menghadirkan game yang seru untuk Anda...",
    ACCOUNT_REQ: "Anda harus mendaftar terlebih dahulu",
    ACCOUNT_REQ_TEXT:
      "Untuk bermain Game, silahkan masuk dahulu ke akun Anda. Jika Anda belum memiliki akun, silahkan daftar dengan cara klik link dibawah ini.",
    ACCOUNT_REQ_TEXT_ESPL:
      "Untuk bermain turnamen ESPL, silahkan masuk dahulu ke akun Anda. Jika Anda belum memiliki akun, silahkan daftar dengan cara klik link dibawah ini.",
    DO_LATER: "Lakukan ini nanti",
    CONF_REQ: "Konfirmasi Diperlukan",
    CONF_REQ_TEXT: "Harap konfirmasi pendaftaran Anda untuk game ini.",
    CONF_REQ_TEXT_ESPL: "Harap konfirmasi pendaftaran Anda untuk ESPL.",
    CURR_PWD: "Password Anda",
    CONF_REQ_SUCCESS: "Registrasi Berhasil",
    CONF_REQ_SUCCESS_TEXT:
      "Pendaftaran Anda berhasil, periksa akun email Anda dan verifikasi email Anda. Kalau begitu kembali ke sini dan mulai mainkan gamenya!",
    CONF_REQ_SUCCESS_TEXT_ESPL:
      "Pendaftaran ESPL Anda berhasil, periksa akun email Anda dan verifikasi email Anda. Kalau begitu kembali ke sini, login ke ESPL dan daftar untuk turnamen!",
    GC_VERIFY_EMAIL: "Email belum Terdaftar",
    GC_VERIFY_EMAIL_TEXT:
      "Anda belum mendaftarkan alamat email di akun Anda. Silahkan pilih Akun Saya dan verifikasi email Anda terlebih dahulu.",
    PLAY_NOW: "Main",
    MORE_DETAILS: "Lihat Detail",

    // Fan Zone
    FZ_TEXT: "Ingin berbagi ilmu sepakbola dengan Indonesia?",
    FZ_WIN_FIFTY:
      "Dapatkan hadiah menarik untuk pemenang penulis artikel terbaik setiap bulan!",
    FZ_REQ_TEXT:
      "Jika ingin submit artikelmu, silahkan Masuk terlebih dahulu menggunakan akun Kita Garuda kamu. Masih belum punya akun? Kamu bisa daftar gratis menggunakan link dibawah ini.",
    FAN_SUBMISSION: "Daftar Fitur Fans",
    FAN_BECOME_WRITER:
      "Jadilah penulis sepak bola yang sesungguhnya di Kita Garuda!",
    FAN_TERMS_APPLY: "Syarat dan ketentuan berlaku",
    FAN_WIN_FIFTY:
      "Kirimkan artikel terbaik kamu dan dapatkan hadiah menarik untuk setiap artikel yang kami tayangkan setiap bulannya!",
    FAN_ENTER_TITLE: "Tulis judul artikelmu disni",
    FAN_ENTER_ARTICLE:
      "Klik kanan dan pilih paste atau tulis artikelmu sini...",
    FAN_ARTICLE_CREATED: "Artikel Disubmit!",
    FAN_ARTICLE_UPDATED: "Artikel Diperbarui!",
    FAN_ARTICLE_SUBM: "Artikel kamu sukses disubmit!",
    FAN_ARTICLE_STATUS: "Kamu bisa lihat status artikelmu di sini.",
    FAN_ARTICLE_DRAFT_CREATED: "Draf Artikel Dibuat!",
    FAN_ARTICLE_DRAFT_UPDATED: "Draf Artikel Diperbarui!",
    FAN_ARTICLE_DRAFT_SAVED: "Draf Disimpan",
    FAN_ARTICLE_DRAFT_SAVED_TEXT: "Draf artikelmu sukses disimpan!",
    FAN_ARTICLE_CONT: "Kamu dapat melanjutkan menulis artikelmu nanti.",
    FAN_ARTICLE_CONT_TWO:
      "Untuk menemukan artikelmu, silahkan kunjungi laman, Cek Status di Fitur Fans atau lanjutkan menulis sekarang.",
    FAN_ARTICLE_SEE_STATUS:
      "Kami akan mengumumkan pemenang untuk artikel terbaik setiap bulannya - silahkan cek secara berkala",
    FAN_ARTICLE_MY: "Artikel Saya",
    SUBMIT_ARTICLE: "Submit artikel saya",
    SUBMIT_OTHER_ARTICLE: "Submit artikel lagi",
    FAN_AGREE_TERMS: "Saya sudah membaca dan menyetujui ",
    FAN_AGREE_TERMS_C: "syarat dan ketentuan Fitur Fans",
    FAN_FORUM_AGREE_TERMS_C: "syarat dan ketentuan Forum Fans",
    FAN_MIN_MAX: "Minimal 200 karakter dan maksimal 5000 karakter",
    FAN_NO_POSTS_YET: "Belum ada postingan",
    FAN_LAST_POST: "Postingan terakhir",
    FAN_COMMENTS: "Komentar",
    FAN_FULL_NAME: "Nama Lengkap",
    FAN_FULL_NAME_PL: "Tulis nama disini...",
    FAN_EMAIL: "Alamat Email",
    FAN_EMAIL_PL: "Tulis email disini...",
    FAN_ADDRESS: "Alamat",
    FAN_ADDRESS_PL: "Tulis alamat disini...",
    FAN_PHONE: "Nomor Telpon",
    FAN_PHONE_PL: "Tulis nomor telpon disini...",
    FAN_GENDER: "Jenis Kelamin",
    FAN_GENDER_PL: "Pilih jenis kelamin...",
    FAN_SLOGAN: "Slogan Anda",
    FAN_SLOGAN_PL: "Tulis slogan Anda disini...",
    FAN_PROFILE_PHOTO: "Foto profil",
    FAN_PROFILE_PHOTO_UPLOAD: "UPLOAD FOTO PROFIL",
    FAN_PROFILE_PHOTO_CAPTION: "Max. Sebuah Foto (400x400px ukuran)",
    FAN_SUBMITTED_YOUR_VOTE: "Anda telah mengirimkan suara",
    FAN_SUBMITTED_YOUR_ANSWER: "Anda telah mengirimkan jawaban Anda",

    // Gallery
    FAN_GALLERY: "Galeri Fans",
    FAN_GALLERY_SUBMITTED:
      "Mengirimkan item pengguna baru untuk galeri penggemar!",
    FAN_GALLERY_DELETED: "Menghapus item pengguna baru untuk galeri penggemar!",
    FAN_GALLERY_PUBLISHED:
      "Galeri Penggemar Diterbitkan dalam keranjang yang dibuat!",
    FAN_GALLERY_UNPUBLISHED:
      "Galeri Penggemar Tidak dipublikasikan dalam keranjang yang dibuat!",
    FAN_GALLERY_MY: "Kiriman Saya yang Diterbitkan",
    FAN_GALLERY_SEE_STATUS: "Silahkan lihat status fotomu dibawah ini.",
    FAN_GALLERY_P: "Fans Terbaik Bulan Ini",
    FAN_GALLERY_TEXT:
      'Ayo tunjukan semangatmu untuk klub kesayanganmu! Upload foto terbaikmu untuk memenangkan hadiah Rp 50,000 jika fotomu terpilih sebagai "Fans Terbaik Bulan Ini"! ',
    FAN_GALLERY_TEXT_M:
      "Kami butuh aksi sepakbola lucu! Upload meme kreatif dan menangkan hadiah Rp 20,000 untuk setiap meme yang dimuat!",
    FAN_GALLERY_MEME: "Galeri Meme",
    FAN_GALLERY_LIKED: "Menyukai foto ini untuk galeri penggemar!",
    FAN_REQ_TEXT:
      "Jika ingin submit fotomu, silahkan Masuk terlebih dahulu menggunakan akun Kita Garuda kamu. Masih belum punya akun? Kamu bisa daftar gratis menggunakan link dibawah ini.",
    FAN_MAX: "Maksimal 5 foto per unggahan per bulan.",
    FAN_WRITE: "Tulis sesuatu mengenai fotomu...",
    FAN_ACCEPT:
      "Dengan ini saya mengonfirmasi bahwa saya mengambil foto-foto ini dan saya bertanggung jawab atas konten yang diunggah.",
    FAN_AGREE_TERMS_P:
      "syarat dan ketentuan Fans of the Month dari Kita Garuda",
    FAN_AGREE_TERMS_M: "syarat dan ketentuan Meme Gallery dari Kita Garuda",
    FAN_SUBMITTED_BY: "Disampaikan oleh",
    FAN_MORE_INFO: "Informasi",

    // Fan Creations
    FAN_CREATIONS: "Kreasi Fans",
    FC_SUBMITTED: "Mengirimkan item pengguna baru untuk kreasi penggemar!",
    FC_DELETED: "Menghapus item pengguna baru untuk kreasi penggemar!",
    FC_PUBLISHED: "Kreasi Penggemar Diterbitkan dalam keranjang yang dibuat!",
    FC_UNPUBLISHED:
      "Kreasi Penggemar Tidak dipublikasikan dalam keranjang yang dibuat!",
    FC_MY: "Kiriman Saya yang Diterbitkan",
    FC_SEE_STATUS: "Silahkan lihat status fotomu dibawah ini.",
    FC_P: "Fans Terbaik Bulan Ini",
    FC_TEXT:
      'Ayo tunjukan semangatmu untuk klub kesayanganmu! Upload foto terbaikmu untuk memenangkan hadiah Rp 50,000 jika fotomu terpilih sebagai "Fans Terbaik Bulan Ini"! ',
    FC_TEXT_M:
      "Kami butuh aksi sepakbola lucu! Upload meme kreatif dan menangkan hadiah Rp 20,000 untuk setiap meme yang dimuat!",
    FC_MEME: "Kreasi Meme",
    FC_LIKED: "Menyukai foto ini untuk kreasi penggemar!",
    FAN_REQ_TEXT:
      "Jika ingin submit fotomu, silahkan Masuk terlebih dahulu menggunakan akun Kita Garuda kamu. Masih belum punya akun? Kamu bisa daftar gratis menggunakan link dibawah ini.",
    FAN_MAX: "Maksimal 5 foto per unggahan per bulan.",
    FAN_WRITE: "Tulis sesuatu mengenai fotomu...",
    FAN_ACCEPT:
      "Dengan ini saya mengonfirmasi bahwa saya mengambil foto-foto ini dan saya bertanggung jawab atas konten yang diunggah.",
    FAN_AGREE_TERMS_P:
      "syarat dan ketentuan Fans of the Month dari Kita Garuda",
    FAN_AGREE_TERMS_M: "syarat dan ketentuan Meme Gallery dari Kita Garuda",
    FAN_SUBMITTED_BY: "Disampaikan oleh",
    FAN_MORE_INFO: "Informasi",

    // Fan Discussion
    FD_VOTE: "Pilihan Fans",
    FD_FORUM: "Forum Fan",
    FD_SEE_MORE: "Lihat topik lainnya >",
    FD_VOTE_NOW: "Pilih Sekarang",
    FD_CAST_VOTE: "Berikan pilihanmu kamu sekarang juga",
    FD_SUBMIT_VOTE: "Submit Pilihanmu",
    FD_SIGN_UP: "Masuk / Daftar untuk Memilih",
    FD_FLAG_COMMENT_TXT: "Harap, Konfirmasi untuk Menandai Komentar ini",
    FD_FLAG_COMMENT: "Menandai Komentar",
    FD_VOTE_REQ_TEXT:
      "Jika ingin submit phili, silahkan Masuk terlebih dahulu menggunakan akun Kita Garuda kamu. Masih belum punya akun? Kamu bisa daftar gratis menggunakan link dibawah ini.",

    // Garuda Store
    PRODUCT_DETAIL: "Detail Produk",
    OTHER_PRODUCT: "Produk Lainnya",
    BUY_HERE: "BELI DISINI",
    TERMS_CONDITIONS: "Syarat dan Ketentuan",
    BOOK_NOW: "Pesan sekarang",

    // Kumpul Bareng
    GET_TOGETHER: "Kumpul Bareng",

    // Profile Fans
    FAN_PROFILE: "Profil Fans",
    SUBMIT_FAN_PROFILE: "Kirim Profil Penggemar",
    FAN_PROFILE_SUBMITTED:
      "Mengirimkan item pengguna baru untuk profil penggemar!",
    FAN_PROFILE_UPDATED:
      "Memperbarui item pengguna baru untuk profil penggemar!",
    FAN_PROFILE_PUBLISHED:
      "Profil Penggemar Diterbitkan dalam keranjang yang dibuat!",
    FAN_PROFILE_UNPUBLISHED:
      "Profil Penggemar Tidak dipublikasikan di keranjang yang dibuat!",

    // Team Squad
    TEAM_NATIONAL: "Tim Nasional",
    INDONESIA: "Indonesia",
    PLAYER_LIST: "Daftar Pemain",
    GET_JERSEY: "Dapatkan Jersey-Nya!",
    APPEARANCES_NUMBER: "Jumlah Penampilan",
    APPEARANCES: "Penampilan",
    PLAY_MINUTES: "Menit Bermain",
    GOALS_NUMBER: "Jumlah Gol",
    FEEDS_NUMBER: "Jumlah Umpan",
    BIRTH_DATE: "Tanggal Lahir",
    BIRTH_PLACE: "Tempat Lahir",
    HEIGHT: "Tinggi",
    WEIGHT: "Berat",
    CURRENT_CLUB: "Klub Saat Ini",

    // Fan Forum
    FF_TOPIC: "Topik Forum Fans",
    FF_ALL_TOPICS: "Semua topik",
    FF_POPULAR: "Paling populer",
    FF_SIGN_UP: "Masuk / Daftar untuk mengirim komentar",
    FF_POST_CLUB_A: "In order to post your comments in this forum, ",
    FF_POST_CLUB_B: "needs to be set as one of your Favourite Clubs.",
    FF_POST_CLUB_B:
      'Head over to "My Account" to pick your Favourite Clubs now!',

    // Indonesia Football
    INDONESIA_FOOTBALL: "Bola Indonesia",
    IND_FIXTURES: "Jadwal",
    IND_TABLE: "Tabel",
    IND_STANDINGS: "Klasemen",
    IND_CLUBS: "Club",
    IND_TIMNAS: "TIMNAS",
    IND_TOP_NEWS: "Top News",
    IND_CLUB_NEWS: "Club News",
    IND_LATEST_NEWS: "Latest News",
    IND_INFORMATION: "Club Information",
    IND_FAN_REEL:
      "Show your passion for your club and upload your pictures to be featured in the fan reel!",

    // Manage Tickets
    TICKET_CREATED: "Tiket Dibuat",
    TICKET_UPDATED: "Tiket Diperbarui",

    // Manage Team Squad
    TEAM_CREATED: "Tim Dibuat",
    TEAM_UPDATED: "Tim Diperbarui",
    TEAM_DELETED: "Tim Dihapus",
    PROFILE_CREATED: "Profil Dibuat",
    PROFILE_UPDATED: "Profil Diperbarui",

    // Manage Photos
    MAX_10_FILES: "Maksimal 10 file sekaligus.",

    // Manage Garuda Store Products
    PRODUCT_CREATED: "Produk Dibuat",
    PRODUCT_UPDATED: "Produk Diperbarui",

    // Manage News
    NEWS_ARTICLE_CREATED: "News Article Created",
    NEWS_ARTICLE_UPDATED: "News Article Updated",
    MAX_FILES: "Maksimal 5 file untuk sekali upload",
    MAX_FILESIZE: "Ukuran maksimum file adalah 7 MB: ",
    MUSIC_UPLOADED: "Music Files Uploaded",
    MUSIC_UPDATED: "Music File Updated",
    RECORD_DELETED: "Item Dihapus",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",

    // Manage Ads
    AD_ARTICLE_CREATED: "Advertisement Created",
    AD_ARTICLE_UPDATED: "Advertisement Updated",

    // Manage Prizes
    PRIZE_CREATED: "Prize Created",
    PRIZE_UPDATED: "Prize Updated",

    // Manage Fan Votes
    AM_VOTE_HEADLINE: "Judul",
    AM_VOTE_QUESTION: "Pertanyaan",
    AM_VOTE_COVER_PHOTO: "Foto sampul",
    AM_VOTE_DESCRIPTION: "Deskripsi",
    AM_VOTING_OPTIONS: "Pilihan Voting",
    AM_VOTING_RESULTS: "Hasil Voting",
    AM_VOTE_ANSWER: "Jawaban",
    AM_VOTE_ANSWER_COLUMN: "Kolom Jawaban",
    AM_VOTE_USER_ANSWER_TEXT: "Teks Jawaban User...",
    AM_VOTE_ANSWER_REQUIRED: "Wajib diisi",
    AM_VOTE_OPTION: "Opsi",
    AM_VOTE_DISCUSSION_ANSWER_RESULTS: "Hasil Jawaban Diskusi",
    AM_VOTE_ANSWER_RESULTS: "Jawaban Diskusi",
    AM_VOTE_SUMMARY: "Ringkasan",
    AM_VOTE_INDIVIDUAL: "Individual",
    AM_VOTE_VIEW_ANSWER: "Lihat Jawaban",
    AM_VOTE_RECEIVE_ANSWERS: "Menerima Jawaban",
    AM_VOTE_EXPORT_ANSWERS: "Export Jawaban",
    AM_VOTE_SORT_BY: "Urutkan Berdasarkan",
    AM_VOTE_ACCOUNT_NAME: "Nama Akun Voters",
    AM_VOTE_LATEST: "Terbaru",

    // Settings
    SETTINGS: "Settings",
    PRO_CH_PL: "Silahkan buat password Anda",
    SET_UPDATED: "Pengaturan Disimpan",
    PWD_CHANGED: "Kata sandi telah diubah",

    // Buttons
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Lanjut",
    BUT_CONTINUE: "Lanjutkan ke akun Anda",
    BUT_CONT_DRAFT: "Lanjut Menulis",
    BUT_SIGN_IN: "Masuk",
    BUT_SIGN_UP: "Daftar",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Kembali",
    BUT_BACK_TO_SIGN_IN: "Kembali ke Masuk",
    BUT_VERIFY: "Verifikasi",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Ubah alamat email Anda",
    BUT_CHANGE: "Ubah",
    BUT_DONE: "Selesai",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Simpan sebagai draft",
    BUT_SUMBIT: "Submit",
    BUT_SUBM_PHOTO: "Submit Foto",
    BUT_VIEW_ALL: "Lihat Detail",
    BUT_ALL_NEWS: "Lihat Semua Berita",
    BUT_ALL_VIDEOS: "Lihat Semua Video",
    BUT_ALL_FF: "Lihat Semua Fan Forum",
    BUT_VIEW_GRID: "Lihat Grid",
    BUT_CONFIRM: "Konfirmasi",
    BUT_CLOSE: "Tutup",
    BUT_CHECK: "Cek Status",
    BUT_CHECK_PHOTO: "Cek Status Penerbitan",
    BUT_LOAD_MORE: "Muat Lainnya",
    SHOW_MORE: "Menampilkan lebih banyak",
    DISCOVER_MORE: "Temukan Lebih Banyak",
    BUT_ESPL_ALREADY: "Saya sudah punya akun di ESPL",

    // ELEVATE
    ELLEVATE_ABOUT: "Tentang Ellevate",
    ELLEVATE_ENTER_HERE: "LANJUT KE ELLEVATE",
  },
};
