
import { LANG } from "@/utils/language";
export default {
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: this.$config.BASE_URL + this.$route.path,
        },
      ],
    };
  },
  props: {
    source: String,
  },
  data: () => ({
    lang: LANG,
    drawer: false,
    snackbar: false,
    snackbarWarning: false,
    snackbarText: "",
    dialogFilePreview: false,
    fileName: "",
    timeout: 3000,
    valid: false,
    welcomeDialog: false,
    welcomeText: "",
    dark: true,
    search: "",
    triggerSearch: false,
    window: {
      width: 0,
      height: 0,
    },
    scrollY: 0,
    items: [
      ["mdi-email", "Inbox"],
      ["mdi-account-supervisor-circle", "Supervisors"],
      ["mdi-clock-start", "Clock-in"],
    ],
    logoSrc: "",
    personSrc: "",
    drawerSrc: "",
    IdFlagIcon: "",
    UkFlagIcon: "",
    liveIcon: "",
    liveIconR: "",
    ticketIcon: "",
    ticketIconR: "",
    cartIcon: "",
    cartIconR: "",
    accountIcon: "",
    accountIconR: "",
    logoutIcon: "",
    logoutIconR: "",
    active4: false,
    active5: false,
    loadFFHome: false,
    loadCBHome: false,
    welcome: {},
    selectedItem: 0,
  }),
  computed: {
    firstName() {
      if (this.getUser && this.getUser.displayName) {
        return this.getUser.displayName.split(" ")[0];
      }
      return "";
    },
    getLanguage() {
      return this.$store.state.user.language || "en";
    },
    mediaBaseUrl() {
      return this.$store.state.mediaBaseUrl;
    },
    mediaOriginUrl() {
      return this.$store.state.mediaOriginUrl;
    },
    show() {
      return this.$store.state.navigation.show;
    },
    showHome() {
      return this.$store.state.navigation.showHome;
    },
    showApp() {
      return this.$store.state.navigation.showApp;
    },
    getWeb3() {
      return this.$store.state.Web3ModuleModule;
    },
    authUser() {
      return this.$store.getters.authUser;
    },
    getUser() {
      return { ...this.$store.state.user };
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    },
    getDark() {
      return this.$store.state.navigation.dark;
    },
    avatar() {
      return this.$store.state.user.avatar;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    this.dark = this.getDark;
    document.addEventListener("deviceready", this.onDeviceReady, false);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    this.logoSrc = this.mediaBaseUrl + "images/logo.png";
    this.personSrc = this.mediaBaseUrl + "icons/person.png";
    this.drawerSrc = this.mediaBaseUrl + "images/sidebar-bg.png";
    this.IdFlagIcon = this.mediaBaseUrl + "icons/Indonesia-flag.png";
    this.UkFlagIcon = this.mediaBaseUrl + "icons/UK-flag.png";
    this.liveIcon = this.mediaBaseUrl + "icons/live-stream.png";
    this.liveIconR = this.mediaBaseUrl + "icons/live-stream-red.png";
    this.ticketIcon = this.mediaBaseUrl + "icons/ticket.png";
    this.ticketIconR = this.mediaBaseUrl + "icons/ticket-red.png";
    this.cartIcon = this.mediaBaseUrl + "icons/shopping-cart.png";
    this.cartIconR = this.mediaBaseUrl + "icons/shopping-cart-red.png";
    this.accountIcon = this.mediaBaseUrl + "icons/user.png";
    this.accountIconR = this.mediaBaseUrl + "icons/user-red.png";
    this.handleResize();
    this.getWelcomeText();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    onDeviceReady() {
      this.$store.commit("SetDevice", {
        platform: device.platform,
      });
    },
    openWelcomeDialog() {
      this.drawer = false;
      this.welcomeDialog = true;
    },
    getWelcomeText() {
      this.drawer = false;
      Promise.resolve(this.$axios.$get("/api/welcome"))
        .then((welcome) => {
          if (welcome) {
            var obj = welcome;
            obj.id = welcome._id;
            this.welcome = obj;
            this.welcomeText = obj.welcome_text;
            this.$store.commit("SetEsplCount", {
              count: obj.espl_banner_clicked,
            });
          }
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
        });
    },
    changeWelcomeText() {
      let dispatchObj = {
        welcome_text: this.welcomeText,
      };
      const id = this.welcome.id;
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      Promise.resolve(
        this.$axios.$put(`/api/welcome/${id}`, dispatchObj, config)
      )
        .then((res) => {
          if (res) {
            this.snackbarText =
              this.lang[this.getLanguage].WELCOME_TEXT_UPDATED;
            this.snackbar = true;
            this.welcomeDialog = false;
          }
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
        });
    },
    searchClicked(e) {
      if (e.keyCode === 8) {
        this.triggerSearch = false;
        return;
      }
      if ((e === "click" || e.keyCode === 13) && this.search !== "") {
        this.triggerSearch = true;
        // if (this.$route.path === '/') {
        this.$router.push("/news?search=" + this.search);
        // }
        return;
      }
      this.triggerSearch = false;
    },
    clearSearch() {
      this.search = "";
      this.triggerSearch = false;
    },
    menuChangeClicked() {
      this.search = "";
      if (window.width < 768) {
        this.drawer = false;
      }
    },
    getWeb3Instance() {
      Promise.resolve(Web3Getter.getWeb3Instance()).then((result) => {
        if (result.injectedWeb3) {
          // Write web3 instance again to store Action / Mutation
          this.$store.commit("Web3Module/setWeb3", result);
        } else {
          this.web3 = result;
          this.web3.currentProvider.publicConfigStore.on(
            "update",
            this.checkMetaMaskChanges
          );
        }
      });
    },
    checkMetaMaskChanges(event) {
      if (!event.isUnlocked && !event.isEnabled) {
        // Unlock MetaMask View
        this.$store.commit("Web3Module/setWeb3Null", null);
      } else if (event.isUnlocked && !event.isEnabled) {
        // Connect View
        this.$store.commit("Web3Module/setWeb3Null", null);
      } else if (event.isUnlocked && event.isEnabled) {
        // Connected
        Promise.resolve(Web3Getter.getWeb3Instance()).then((result) => {
          this.$store.commit("Web3Module/setWeb3", result);
          // Next Firebase Sign Up stuff in store Action / Mutation
        });
      }
    },
    changeLanguage() {
      this.$store.commit("SetLanguage", {
        language: this.getLanguage === "en" ? "id" : "en",
      });
    },
    openPrivacy() {
      if (this.getLanguage === "en") {
        this.fileName =
          this.mediaOriginUrl +
          "privacy/KitaGaruda%20Privacy%20Policy%20ENG.pdf";
      } else if (this.getLanguage === "id") {
        this.fileName =
          this.mediaOriginUrl +
          "privacy/KitaGaruda%20Privacy%20Policy%20BAHASA.pdf";
      }
      this.dialogFilePreview = true;
    },
    openTerms() {
      if (this.getLanguage === "en") {
        this.fileName =
          this.mediaOriginUrl +
          "terms/KitaGaruda%20Terms%20and%20Conditions%20En.pdf";
      } else if (this.getLanguage === "id") {
        this.fileName =
          this.mediaOriginUrl +
          "terms/KitaGaruda%20Terms%20and%20Conditions%20Bahasa.pdf";
      }
      this.dialogFilePreview = true;
    },
    gotoLink(link) {
      window.open(link, "_blank");
    },
    async signOut(role) {
      if (role == "user") {
        await this.$fire.auth.signOut();
        this.$store.commit("SetUserDetails", { isLoggedIn: false });
        this.$store.commit("setUser", null);
        this.$router.push("/login");
      } else {
        await this.$auth.logout();
        this.$store.commit("SetUserDetails", { isLoggedIn: false });
        this.$router.push("/admin/login");
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (window.scrollY > 1200) {
        // Trigger Load Fan Features on Home Page
        this.loadFFHome = true;
      }
      if (window.scrollY > 1600) {
        // Trigger Load Fan Features on Home Page
        this.loadCBHome = true;
      }
    },
  },
};
