export const  state = () => ({
  collections: [],
  publicAds: [],
  gametypes: [],
  games: [],
  prizes: []
})

export const mutations = {
  setCollections (state, payload) {
    state.collections = payload
  },
  setPublicAds (state, payload) {
    state.publicAds = payload
  },
  setGameTypes (state, payload) {
    state.gametypes = payload
  },
  setGames (state, payload) {
    state.games = payload
  },
  setPrizes (state, payload) {
    state.prizes = payload
  }
}

export const  actions = {
  getPublicAds ({commit}, payload) {
    const today = new Date().getTime()
    var params = {
      limit: payload.limit,
      status: 2
    }
    Promise.resolve(this.$axios.$get('/api/ads', { params }))
      .then(docs => {
        if (docs.length > 0) {
          var adList = []
          docs.forEach(doc => {
            var obj = doc
            obj.id = doc._id
            obj.checkTime = Math.round(today / 1000 + 3600)
            obj.isLocalImg = true
            if (doc.media.indexOf('http') > -1) {
              obj.isLocalImg = false
            }
            if (today >= obj.publish && today <= obj.expire) {
              adList.push(obj)
            }
          })
          if (adList.length > 8) {
            // adList.splice(8, 0, obj)
          }
          commit('setPublicAds', adList)
        }
      })
      .catch(err => {
        console.log(`Error getting documents: ${err}`)
      })
  },
  getPrizes ({commit}, payload) {
    const today = new Date().getTime()
    var params = {
      status: 2
    }
    Promise.resolve(this.$axios.$get('/api/prizes', { params }))
      .then(docs => {
        if (docs.length > 0) {
          var prizes = []
          docs.forEach(doc => {
            var obj = doc
            obj.id = doc._id
            obj.checkTime = Math.round(today / 1000 + 3600)
            obj.readmore = false
            if (today >= obj.publish && today <= obj.expire) {
              prizes.push(obj)
            }
          })
          commit('setPrizes', prizes)
        }
      })
      .catch(err => {
        console.log(`Error getting documents: ${err}`)
      })
  },
  getGames ({commit}, payload) {
    const today = new Date().getTime()
    var params = {
      status: 2
    }
    Promise.resolve(this.$axios.$get('/api/games', { params }))
      .then(docs => {
        if (docs.length > 0) {
          var games = []
          docs.forEach(doc => {
            var obj = doc
            obj.id = doc._id
            obj.checkTime = Math.round(today / 1000 + 3600)
            obj.readmore = false
            if (today >= obj.publish && today <= obj.expire) {
              games.push(obj)
            }
            
          })
          commit('setGames', games)
        }
      })
      .catch(err => {
        console.log(`Error getting documents: ${err}`)
      })
  },
  getActiveGames ({commit}, payload) {
    const today = new Date().getTime()
    var params = {
      status: 2
    }
    Promise.resolve(this.$axios.$get('/api/gametypes', { params }))
      .then(docs => {
        if (docs.length > 0) {
          var gametypes = []
          docs.forEach(doc => {
            var obj = doc
            obj.id = doc._id
            obj.checkTime = Math.round(today / 1000 + 3600)
            gametypes.push(obj)
          })
          commit('setGameTypes', gametypes)
        }
      })
      .catch(err => {
        console.log(`Error getting documents: ${err}`)
      })
  },
  updateLastLogin ({commit}, payload) {
    var obj = {
      lastlogin: new Date().getTime()
    }
    const config = {
      headers: {
      'Content-Type': 'application/json; charset=utf-8'
      }
    }
    Promise.resolve(this.$axios.$put(`/api/users/${payload.id}`, obj, config))
      .then(res => {
        if (res) {
          console.log('Last Login in bucket updated')
        }
      })
      .catch(err => {
        console.log(`Encountered error: ${err}`)
      })
  },
  postForumComment ({commit}, payload) {
    const message = {
      comment: payload.comment,
      status: 2,
      name: payload.name,
      uid: payload.uid,
      pid: payload.pid,
      created: new Date().getTime(),
      avatar: payload.avatar
    }
    const config = {
      headers: {
      'Content-Type': 'application/json; charset=utf-8'
      }
    }
    Promise.resolve(this.$axios.$put(`/api/fanforums/${payload.postkey}/comments`, message, config))
      .then(res => {
        if (res) {
          console.log('Fan Forum Comment created')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
          this.init()
        }
      })
      .catch(err => {
        console.log(`Encountered error: ${err}`)
        this.addNewsItem = false
      })
  }
}

export const getters = {
  collections (state) {
    return state.collections
  },
  publicAds (state) {
    return state.publicAds
  },
  gametypes (state) {
    return state.gametypes
  },
  games (state) {
    return state.games
  },
  prizes (state) {
    return state.prizes
  }
}