export const state = () => ({
  user: null
})

export const mutations = {
  setUser (state, payload) {
    state.user = payload
  }
}

export const actions = {
  signUserIn ({commit}, payload) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  insertUserForSignUp ({commit}, payload) {
    const message = {
      uid: payload.uid,
      name: payload.name,
      about_me: '',
      full_name: payload.name,
      address: '',
      date_of_birth: '',
      sex: 0,
      email: payload.email,
      gravatar: true,
      avatar: '',
      accounts: [payload.coinbase],
      acc_balance: 0,
      status: payload.status,
      link_verified: false,
      id_verified: false,
      email_verified: payload.email_verified,
      phone_verified: payload.phone_verified,
      id_type: 0,
      acc_level: 1,
      acc_type: payload.acc_type, // TBD
      terms_accepted: true,
      language: payload.language,
      created: payload.created,
      lastlogin: payload.created,
      interests_favorites: [],
      interests_type: [],
      followers:[],
      following:[],
      my_leagues: [],
      my_teams: [],
      my_teams_last_update: 0,
      kicktip: false,
      promo_code: "OLAHBOLA",
      platform: "OLAHBOLA",
      ellevate_user: 'no',
      date_subscription_started: '',
      date_of_last_renewal: '',
      payment_code: ''
    }
    if (payload.acc_type == 1) {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
          }
      }
      Promise.resolve(this.$axios.$post('/api/users', message, config))
        .then(res => {
          if (res) {
            console.log('User Account in bucket updated')
          }
        })
        .catch(err => {
          console.log(`Encountered error: ${err}`)
        })
    }
  },
  userLoginMagicLink ({commit}, payload) {
    const message = {
      link_verified: payload.link_verified
    }
    let docRef = this.$fire.firestore.collection('users').doc(payload.postkey).update(message)
      .then(ref => {
          console.log('User Account in bucket updated')
        })
      .catch(error => {
          console.log(error)
        })
  },
  magicLinkClickedForSignUp ({commit}, payload) {
    const message = {
      uid: payload.uid,
      status: payload.status,
      link_verified: payload.link_verified,
      lastlogin: payload.lastlogin
    }
    let docRef = this.$fire.firestore.collection('users').doc(payload.postkey).update(message)
      .then(ref => {
          console.log('User Account in bucket Updated')
        })
      .catch(error => {
          console.log(error)
        })
  },
  signUserInGoogle ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInFacebook ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInGithub ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new firebase.auth.GithubAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInTwitter ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  autoSignIn ({commit}, payload) {
    commit('setUser', {
      id: payload.uid,
      name: payload.displayName,
      email: payload.email,
      photoUrl: payload.photoURL
    })
  },
  logout ({commit}) {
    this.$fire.auth.signOut()
    commit('setUser', null)
  }
}

export const getters = {
  user (state) {
    return state.user
  }
}