export const state = () => ({
  isInjected: false,
  web3Instance: null,
  networkId: null,
  coinbase: null,
  balance: null,
  mmLoggedIn: false,
  error: null,
  contractInstance: null
})

export const mutations = {
  setWeb3 (state, payload) {
    state.isInjected = payload.injectedWeb3
    state.Web3ModuleInstance = payload.web3()
    state.networkId = payload.networkId
    state.coinbase = payload.coinbase
    state.balance = payload.balance
    state.mmLoggedIn = true
  },
  setWeb3Null (state, payload) {
    state.isInjected = null
    state.Web3ModuleInstance = null
    state.networkId = null
    state.coinbase = null
    state.balance = null
    state.mmLoggedIn = null
  }
}

export const actions = {
}

export const getters = {
}
