export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const CustomAgile = () => import('../../components/CustomAgile.vue' /* webpackChunkName: "components/custom-agile" */).then(c => wrapFunctional(c.default || c))
export const AppAccAccount = () => import('../../components/app/AccAccount.vue' /* webpackChunkName: "components/app-acc-account" */).then(c => wrapFunctional(c.default || c))
export const AppAccAds = () => import('../../components/app/AccAds.vue' /* webpackChunkName: "components/app-acc-ads" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanFeatures = () => import('../../components/app/AccFanFeatures.vue' /* webpackChunkName: "components/app-acc-fan-features" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanForum = () => import('../../components/app/AccFanForum.vue' /* webpackChunkName: "components/app-acc-fan-forum" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanGallery = () => import('../../components/app/AccFanGallery.vue' /* webpackChunkName: "components/app-acc-fan-gallery" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanProfile = () => import('../../components/app/AccFanProfile.vue' /* webpackChunkName: "components/app-acc-fan-profile" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanVotesCopy = () => import('../../components/app/AccFanVotes_copy.vue' /* webpackChunkName: "components/app-acc-fan-votes-copy" */).then(c => wrapFunctional(c.default || c))
export const AppAccGames = () => import('../../components/app/AccGames.vue' /* webpackChunkName: "components/app-acc-games" */).then(c => wrapFunctional(c.default || c))
export const AppAccHome = () => import('../../components/app/AccHome.vue' /* webpackChunkName: "components/app-acc-home" */).then(c => wrapFunctional(c.default || c))
export const AppAccLogin = () => import('../../components/app/AccLogin.vue' /* webpackChunkName: "components/app-acc-login" */).then(c => wrapFunctional(c.default || c))
export const AppAccMedia = () => import('../../components/app/AccMedia.vue' /* webpackChunkName: "components/app-acc-media" */).then(c => wrapFunctional(c.default || c))
export const AppAccNews = () => import('../../components/app/AccNews.vue' /* webpackChunkName: "components/app-acc-news" */).then(c => wrapFunctional(c.default || c))
export const AppAccNewsReport = () => import('../../components/app/AccNewsReport.vue' /* webpackChunkName: "components/app-acc-news-report" */).then(c => wrapFunctional(c.default || c))
export const AppAccPhotos = () => import('../../components/app/AccPhotos.vue' /* webpackChunkName: "components/app-acc-photos" */).then(c => wrapFunctional(c.default || c))
export const AppAccPrizes = () => import('../../components/app/AccPrizes.vue' /* webpackChunkName: "components/app-acc-prizes" */).then(c => wrapFunctional(c.default || c))
export const AppAccProducts = () => import('../../components/app/AccProducts.vue' /* webpackChunkName: "components/app-acc-products" */).then(c => wrapFunctional(c.default || c))
export const AppAccTickets = () => import('../../components/app/AccTickets.vue' /* webpackChunkName: "components/app-acc-tickets" */).then(c => wrapFunctional(c.default || c))
export const AppAccTopSkor = () => import('../../components/app/AccTopSkor.vue' /* webpackChunkName: "components/app-acc-top-skor" */).then(c => wrapFunctional(c.default || c))
export const AppAccVideos = () => import('../../components/app/AccVideos.vue' /* webpackChunkName: "components/app-acc-videos" */).then(c => wrapFunctional(c.default || c))
export const AppAccVideosReport = () => import('../../components/app/AccVideosReport.vue' /* webpackChunkName: "components/app-acc-videos-report" */).then(c => wrapFunctional(c.default || c))
export const AppMolaTV = () => import('../../components/app/MolaTV.vue' /* webpackChunkName: "components/app-mola-t-v" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthSignup = () => import('../../components/auth/Signup.vue' /* webpackChunkName: "components/auth-signup" */).then(c => wrapFunctional(c.default || c))
export const AuthWelcome = () => import('../../components/auth/Welcome.vue' /* webpackChunkName: "components/auth-welcome" */).then(c => wrapFunctional(c.default || c))
export const UserFanDiscussion = () => import('../../components/user/FanDiscussion.vue' /* webpackChunkName: "components/user-fan-discussion" */).then(c => wrapFunctional(c.default || c))
export const UserFanDiscussionCopy = () => import('../../components/user/FanDiscussion_copy.vue' /* webpackChunkName: "components/user-fan-discussion-copy" */).then(c => wrapFunctional(c.default || c))
export const UserFanForumDetail = () => import('../../components/user/FanForumDetail.vue' /* webpackChunkName: "components/user-fan-forum-detail" */).then(c => wrapFunctional(c.default || c))
export const UserFanGallery = () => import('../../components/user/FanGallery.vue' /* webpackChunkName: "components/user-fan-gallery" */).then(c => wrapFunctional(c.default || c))
export const UserFanProfile = () => import('../../components/user/FanProfile.vue' /* webpackChunkName: "components/user-fan-profile" */).then(c => wrapFunctional(c.default || c))
export const UserFanReel = () => import('../../components/user/FanReel.vue' /* webpackChunkName: "components/user-fan-reel" */).then(c => wrapFunctional(c.default || c))
export const UserFanSubmission = () => import('../../components/user/FanSubmission.vue' /* webpackChunkName: "components/user-fan-submission" */).then(c => wrapFunctional(c.default || c))
export const UserFanVote = () => import('../../components/user/FanVote.vue' /* webpackChunkName: "components/user-fan-vote" */).then(c => wrapFunctional(c.default || c))
export const UserKumpulBareng = () => import('../../components/user/KumpulBareng.vue' /* webpackChunkName: "components/user-kumpul-bareng" */).then(c => wrapFunctional(c.default || c))
export const UserMyAccount = () => import('../../components/user/MyAccount.vue' /* webpackChunkName: "components/user-my-account" */).then(c => wrapFunctional(c.default || c))
export const UserHome = () => import('../../components/user/UserHome.vue' /* webpackChunkName: "components/user-home" */).then(c => wrapFunctional(c.default || c))
export const WebAbout = () => import('../../components/web/About.vue' /* webpackChunkName: "components/web-about" */).then(c => wrapFunctional(c.default || c))
export const WebComingSoon = () => import('../../components/web/ComingSoon.vue' /* webpackChunkName: "components/web-coming-soon" */).then(c => wrapFunctional(c.default || c))
export const WebContact = () => import('../../components/web/Contact.vue' /* webpackChunkName: "components/web-contact" */).then(c => wrapFunctional(c.default || c))
export const WebEllevate = () => import('../../components/web/Ellevate.vue' /* webpackChunkName: "components/web-ellevate" */).then(c => wrapFunctional(c.default || c))
export const WebFanZone = () => import('../../components/web/FanZone.vue' /* webpackChunkName: "components/web-fan-zone" */).then(c => wrapFunctional(c.default || c))
export const WebGames = () => import('../../components/web/Games.vue' /* webpackChunkName: "components/web-games" */).then(c => wrapFunctional(c.default || c))
export const WebGarudaStore = () => import('../../components/web/GarudaStore.vue' /* webpackChunkName: "components/web-garuda-store" */).then(c => wrapFunctional(c.default || c))
export const WebHome = () => import('../../components/web/Home.vue' /* webpackChunkName: "components/web-home" */).then(c => wrapFunctional(c.default || c))
export const WebIndFootball = () => import('../../components/web/IndFootball.vue' /* webpackChunkName: "components/web-ind-football" */).then(c => wrapFunctional(c.default || c))
export const WebLiveStreaming = () => import('../../components/web/LiveStreaming.vue' /* webpackChunkName: "components/web-live-streaming" */).then(c => wrapFunctional(c.default || c))
export const WebMatchCenter = () => import('../../components/web/MatchCenter.vue' /* webpackChunkName: "components/web-match-center" */).then(c => wrapFunctional(c.default || c))
export const WebMatchDetail = () => import('../../components/web/MatchDetail.vue' /* webpackChunkName: "components/web-match-detail" */).then(c => wrapFunctional(c.default || c))
export const WebNews = () => import('../../components/web/News.vue' /* webpackChunkName: "components/web-news" */).then(c => wrapFunctional(c.default || c))
export const WebPhoto = () => import('../../components/web/Photo.vue' /* webpackChunkName: "components/web-photo" */).then(c => wrapFunctional(c.default || c))
export const WebPrivacyPolicy = () => import('../../components/web/PrivacyPolicy.vue' /* webpackChunkName: "components/web-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const WebPrizeCentre = () => import('../../components/web/PrizeCentre.vue' /* webpackChunkName: "components/web-prize-centre" */).then(c => wrapFunctional(c.default || c))
export const WebTeamSquad = () => import('../../components/web/TeamSquad.vue' /* webpackChunkName: "components/web-team-squad" */).then(c => wrapFunctional(c.default || c))
export const WebTickets = () => import('../../components/web/Tickets.vue' /* webpackChunkName: "components/web-tickets" */).then(c => wrapFunctional(c.default || c))
export const WebVideos = () => import('../../components/web/Videos.vue' /* webpackChunkName: "components/web-videos" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanVotesVoteAnswer = () => import('../../components/app/AccFanVotes/VoteAnswer.vue' /* webpackChunkName: "components/app-acc-fan-votes-vote-answer" */).then(c => wrapFunctional(c.default || c))
export const AppAccFanVotes = () => import('../../components/app/AccFanVotes/index.vue' /* webpackChunkName: "components/app-acc-fan-votes" */).then(c => wrapFunctional(c.default || c))
export const AppAccTeamSquadProfile = () => import('../../components/app/AccTeamSquad/Profile.vue' /* webpackChunkName: "components/app-acc-team-squad-profile" */).then(c => wrapFunctional(c.default || c))
export const AppAccTeam = () => import('../../components/app/AccTeamSquad/Team.vue' /* webpackChunkName: "components/app-acc-team" */).then(c => wrapFunctional(c.default || c))
export const AppAccTeamSquad = () => import('../../components/app/AccTeamSquad/index.vue' /* webpackChunkName: "components/app-acc-team-squad" */).then(c => wrapFunctional(c.default || c))
export const WebCompBackButton = () => import('../../components/web/comp/BackButton.vue' /* webpackChunkName: "components/web-comp-back-button" */).then(c => wrapFunctional(c.default || c))
export const WebCompClubDetail = () => import('../../components/web/comp/ClubDetail.vue' /* webpackChunkName: "components/web-comp-club-detail" */).then(c => wrapFunctional(c.default || c))
export const WebCompJSBanner = () => import('../../components/web/comp/JSBanner.vue' /* webpackChunkName: "components/web-comp-j-s-banner" */).then(c => wrapFunctional(c.default || c))
export const WebCompLeagueDetail = () => import('../../components/web/comp/LeagueDetail.vue' /* webpackChunkName: "components/web-comp-league-detail" */).then(c => wrapFunctional(c.default || c))
export const WebCompMembershipCard = () => import('../../components/web/comp/MembershipCard.vue' /* webpackChunkName: "components/web-comp-membership-card" */).then(c => wrapFunctional(c.default || c))
export const WebCompNewsArticle = () => import('../../components/web/comp/NewsArticle.vue' /* webpackChunkName: "components/web-comp-news-article" */).then(c => wrapFunctional(c.default || c))
export const WebCompPhotoDetail = () => import('../../components/web/comp/PhotoDetail.vue' /* webpackChunkName: "components/web-comp-photo-detail" */).then(c => wrapFunctional(c.default || c))
export const WebCompPopupBanner = () => import('../../components/web/comp/PopupBanner.vue' /* webpackChunkName: "components/web-comp-popup-banner" */).then(c => wrapFunctional(c.default || c))
export const WebCompProductDetail = () => import('../../components/web/comp/ProductDetail.vue' /* webpackChunkName: "components/web-comp-product-detail" */).then(c => wrapFunctional(c.default || c))
export const WebCompTicketDetail = () => import('../../components/web/comp/TicketDetail.vue' /* webpackChunkName: "components/web-comp-ticket-detail" */).then(c => wrapFunctional(c.default || c))
export const WebCompVideoAd = () => import('../../components/web/comp/VideoAd.vue' /* webpackChunkName: "components/web-comp-video-ad" */).then(c => wrapFunctional(c.default || c))
export const WebTeamCompProfileDetail = () => import('../../components/web/teamComp/ProfileDetail.vue' /* webpackChunkName: "components/web-team-comp-profile-detail" */).then(c => wrapFunctional(c.default || c))
export const WebTeamCardComp = () => import('../../components/web/teamComp/TeamCardComp.vue' /* webpackChunkName: "components/web-team-card-comp" */).then(c => wrapFunctional(c.default || c))
export const WebTeamList = () => import('../../components/web/teamComp/TeamList.vue' /* webpackChunkName: "components/web-team-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
